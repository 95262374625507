// ! Hooks
import { useEmbedApp, useTrans } from "@haravan/reactapp";
import useApp from "hooks/useApp";
import useHistory from "hooks/useHistory";
import { useStore } from "pages/ReportStandard/context/store";
import { Fragment, useState } from "react";
import { useGTM } from "hooks/useGTM";

// ! Components
import {
  Box, Button, Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import HEditable from "components/Editable";
import IconLock from "components/Icons/IconLock";
import HLimitLongString from "components/LimitLongString";
import PrintMachine from "components/PrintMachine";
import RoleCheck from "components/RoleCheck";
import TooltipWithTouch from "components/TooltipWithTouch";
import Permission from "components/Tooltips/Permission";
import ConfigRFM from "components/ConfigRFM";

// ! Helpers and Constants
import { buildQueryString, isEmpty } from "helpers/helpers";
import Model from "models";

const HButton: any = Button;


const allowedAboutBtn = [
  "stock_remaining_days", // Báo cáo sắp hết hàng
  'sales_orders_salechannel_branch', // Báo cáo Kênh sàn thương mại điện tử
  "cas_transaction_pnl", // Báo cáo kết quả hoạt động kinh doanh
  'stock_transactionhistory_adv', // Xuất nhập tồn theo sản phẩm
  'stock_transactionhistory_alltime', //  Xuất nhập tồn theo thời gian
  "sales_order_assigned_location", // Báo cáo theo kho xuất,
  'sales_payments_paiddate', // Báo cáo theo dòng tiền
  'sales_transaction_by_vat', // Báo cáo VAT
  'customers_new_and_return', // Báo cáo khách hàng mói, cũ
  'customers_by_rfm' // Báo cáo Khách hàng theo chỉ số RFM
];

export default function ReportHeader({
  isSystemReport,
  type,
  reportName,
  onClickDuplicate,
  onClickDelete,
  onClickRefresh,
  isAccessReportDiscount,
  onHandleOnClickAbout,
  reportNameSystem,
  typeFilterFromListing,
  isFavoriteFromListing,
  historyPath,
  searchKeyFromListing,
  authors,
  isFavourite,
  exportId,
  onClickExport,
  handlePrintApi,
  dataPrint,
  isLoadingPrint,
  canReadMACost,
  headers,
  sortValue,
  groupBySelected,
  dataLink,
  optionsFilter,
  reportId,
  currentFilter,
  isAddNewReport,
  summary
}: any) {
  const { push } = useHistory();
  const t = useTrans();
  const { isWebview } = useApp();
  const { showMessage } = useEmbedApp();
  const { actions, dispatch, state } = useStore();
  const { discount } = state;
  const { attributes, detectClass } = useGTM({
    enabled: true,
    variables: {
      "ut-screen": "detail",
      "ut-name": 'about'
    }
  });

  const {
    EditReport
  } = Model();

  const [errorMgs, setErrorMgs] = useState("");

  const backHandler = () => {
    let queryString = '';
    let queryParams: any = {
      type: "",
      isFavorite: isFavoriteFromListing,
      s: ''
    }

    if (typeFilterFromListing.length) {
      queryParams = {
        ...queryParams,
        type: typeFilterFromListing
      }
    }

    if (searchKeyFromListing?.length) {
      queryParams = {
        ...queryParams,
        s: searchKeyFromListing
      }
    }

    if (authors.length) {
      queryParams = {
        ...queryParams,
        authors
      }
    }

    queryString = buildQueryString(queryParams);

    if (isWebview) {
      push(`/${historyPath}`)
    } else {
      push(`/listing${queryString}`);
    }
  };

  const handleRefresh = () => {
    onClickRefresh();
  };

  const renderMobile = () => {
    return (
      <div className="hrv-report-xs-d-flex hrv-report-md-d-none hrv-report-items-center hrv-report-justify-end">
        <Menu placement="bottom-end">
          <MenuButton as={Button} variant="default-default">
            {svgMoreIcon}
          </MenuButton>

          <MenuList>
            {allowedAboutBtn.includes(reportNameSystem) && <MenuItem as={Box}>
              <Button variant="ghost-default" onClick={() => {
                onHandleOnClickAbout &&
                  onHandleOnClickAbout(reportNameSystem);
              }} style={{
                width: "100%",
                justifyContent: "start"
              }}
                className={detectClass}
                {...attributes}
              >
                {t("Giới thiệu")}
              </Button>
            </MenuItem>
            }

            <MenuItem as={Box}>
              <RoleCheck
                key="print"
                validRoles={[
                  "admin", "com_api.admin", "com_api.report.export"
                ]}
                render={(allowed) => {
                  return (
                    <Permission allowed={allowed}>
                      <PrintMachine
                        reportName={reportName}
                        reportNameSystem={reportNameSystem}
                        dataPrint={dataPrint}
                        isLoadingPrint={isLoadingPrint}
                        canReadMACost={canReadMACost}
                        headers={headers}
                        sortValue={sortValue}
                        groupBySelected={groupBySelected}
                        dataLink={dataLink}
                        isLoadingTable={false}
                        handlePrintApi={handlePrintApi}
                        optionsFilter={optionsFilter}
                        currentFilter={currentFilter}
                        allowed={allowed}
                      />
                    </Permission>
                  );
                }}
              ></RoleCheck>
            </MenuItem>


            <MenuItem as={Box}>
              <RoleCheck
                key="export"
                validRoles={[
                  "admin", "com_api.admin", "com_api.report.export"
                ]}
                render={(allowed) => {
                  return (
                    <Permission allowed={allowed}>
                      <Button variant="ghost-default"
                        isDisabled={!allowed || checkActiveExportButton()}
                        onClick={() => {
                          if (!allowed) return;

                          !exportId && onClickExport && onClickExport('export');
                        }}
                        style={{
                          width: "100%",
                          justifyContent: "start"
                        }}
                      >
                        {t("export_report")}
                      </Button>
                    </Permission>
                  );
                }}
              ></RoleCheck>
            </MenuItem>

            {reportNameSystem !== "customers_by_rfm" &&
              <MenuItem as={Box}>
                <RoleCheck
                  validRoles={[
                    "admin",
                    "com_api.admin",
                    "com_api.report.write",
                  ]}
                  render={(allowed) => {
                    return (
                      <Permission allowed={allowed}>
                        <HButton
                          rightIcon={!allowed && <IconLock />}
                          onClick={() => {
                            allowed && onClickDuplicate && onClickDuplicate();
                          }} variant="ghost-default"
                          style={{
                            width: "100%",
                            justifyContent: "start"
                          }}
                        >
                          {t("duplicate_report")}
                        </HButton>
                      </Permission>
                    );
                  }}
                ></RoleCheck>
              </MenuItem>
            }

            <MenuItem as={Box}>
              <RoleCheck
                key="configRFM"
                validRoles={["admin", "com_api.admin"]}
                render={(allowed) => {
                  return (
                    <>
                      {reportNameSystem === "customers_by_rfm" ?
                        <ConfigRFM
                          allowed={allowed}
                          isDisplay={
                            reportNameSystem === "customers_by_rfm"
                          }
                          onHandleRefresh={handleRefresh}
                        /> : <></>}
                    </>
                  );
                }}
              />
            </MenuItem>

            {!isSystemReport && (
              <MenuItem as={Box}>
                <RoleCheck
                  validRoles={[
                    "admin",
                    "com_api.admin",
                    "com_api.report.write",
                  ]}
                  render={(allowed) => {
                    return (
                      <Permission allowed={allowed}>
                        <HButton rightIcon={!allowed && <IconLock />}
                          onClick={() => {
                            allowed && onClickDelete && onClickDelete();
                          }}
                          variant="ghost-danger" style={{
                            width: "100%",
                            justifyContent: "start"
                          }}>
                          {t("delete_report")}
                        </HButton>
                      </Permission>
                    );
                  }}
                ></RoleCheck>
              </MenuItem>
            )}

            <MenuItem as={Box}>
              <Button variant="ghost-default" onClick={() => {
                handleRefresh();
              }} style={{
                width: "100%",
                justifyContent: "start"
              }}

              >
                {t("refresh_page")}
              </Button>
            </MenuItem>
          </MenuList>
        </Menu >
      </div >
    );
  };

  const renderDesktop = () => {
    let buttons = [] as Array<any>;
    let placement = !isSystemReport ? "right" : "top";

    buttons.push(
      <>
        {reportNameSystem !== "customers_by_rfm" ? <RoleCheck
          key="duplicate"
          validRoles={["admin", "com_api.admin", "com_api.report.write"]}
          render={(allowed) => {
            return (
              <Permission allowed={allowed} placement={placement}>
                <HButton
                  variant={buttons.length > 1 ? "default-tertiary" : "default-default"}
                  onClick={() => {
                    allowed && onClickDuplicate && onClickDuplicate();
                  }}
                  rightIcon={!allowed && <IconLock />}
                  isDisabled={!allowed}
                  style={{
                    width: !isSystemReport ? "100%" : "",
                    justifyContent: !isSystemReport ? "start" : '',
                    fontWeight: buttons.length > 1 ? 400 : 600,
                  }}
                >
                  {t("duplicate_report")}
                </HButton>
              </Permission>
            );
          }}
        /> : <></>}
      </>,
    );

    if (!isSystemReport) {
      buttons.push(
        <RoleCheck
          key="remove"
          validRoles={["admin", "com_api.admin", "com_api.report.write"]}
          render={(allowed) => {
            return (
              <Permission allowed={allowed} placement={placement}>
                <HButton
                  variant={buttons.length > 1 ? "critical-tertiary" : "critical-default"}
                  onClick={() => {
                    allowed && onClickDelete && onClickDelete();
                  }} rightIcon={!allowed && <IconLock />}
                  isDisabled={!allowed} style={{
                    width: "100%",
                    justifyContent: "start",
                    fontWeight: 400,
                  }}
                >
                  {t("delete_report")}
                </HButton>
              </Permission>
            );
          }}
        ></RoleCheck>,
      );
    }

    return (
      <div
        className="hrv-report-xs-d-none hrv-report-md-d-block"
        style={{ flexGrow: 0, flexShrink: 0 }}
      >
        <div className="hrv-report-md-d-flex hrv-report-items-center hrv-report-justify-end">
          {allowedAboutBtn.includes(reportNameSystem) && (
            <Button variant="default-default"
              onClick={() => {
                onHandleOnClickAbout && onHandleOnClickAbout(reportNameSystem);
              }}
              className={`hrv-report-mr-8 ${detectClass}`}
              {...attributes}
            >
              {t("Giới thiệu")}
            </Button>
          )}

          <RoleCheck
            key="configRFM"
            validRoles={["admin", "com_api.admin"]}
            render={(allowed) => {
              return (
                <>
                  {reportNameSystem === "customers_by_rfm" ?
                    <ConfigRFM
                      allowed={allowed}
                      isDisplay={
                        reportNameSystem === "customers_by_rfm"
                      }
                      onHandleRefresh={handleRefresh}
                    /> : <></>}
                </>
              );
            }}
          />

          <RoleCheck
            key="print"
            validRoles={["admin", "com_api.admin", "com_api.report.export"]}
            render={(allowed) => {
              return (
                <Permission allowed={allowed} placement={placement}>
                  <PrintMachine
                    reportName={reportName}
                    reportNameSystem={reportNameSystem}
                    dataPrint={dataPrint}
                    isLoadingPrint={isLoadingPrint}
                    canReadMACost={canReadMACost}
                    headers={headers}
                    sortValue={sortValue}
                    groupBySelected={groupBySelected}
                    dataLink={dataLink}
                    isLoadingTable={false}
                    handlePrintApi={handlePrintApi}
                    optionsFilter={optionsFilter}
                    currentFilter={currentFilter}
                    allowed={allowed}
                  />
                </Permission>
              );
            }}
          />

          <RoleCheck
            key="export"
            validRoles={["admin", "com_api.admin", "com_api.report.export"]}
            render={(allowed) => {
              return (
                <Permission allowed={allowed} placement={placement}>
                  <Button
                    variant="default-default"
                    isDisabled={!allowed || checkActiveExportButton()} onClick={() => {
                      if (!allowed) return;

                      !exportId && onClickExport && onClickExport('export');
                    }}
                    className="hrv-report-ml-8"
                  >
                    {t("export_report")}
                  </Button>
                </Permission>
              );
            }}
          />

          {buttons.length > 1 ? (
            <Menu placement="bottom-end" size="m" >
              <MenuButton ml={4} as={Button} variant="default-default" rightIcon={svgDropdownIcon}>
                {t("actions")}
              </MenuButton>

              <MenuList>
                {buttons.map((button, index) => {
                  return <Fragment key={index}><MenuItem as={Box}>{button}</MenuItem></Fragment>;
                })}
              </MenuList>
            </Menu>
          ) : (
            <Box ml={4}>{buttons}</Box>
          )}

          <TooltipWithTouch label={t("refresh_page")}
            placement="top-end"
            hasArrow
          >
            <Button variant="default-default"
              onClick={handleRefresh}
              rightIcon={svgRefresh}
              iconSpacing={0}
              w={"32px"}
              ml={reportNameSystem === "customers_by_rfm" ? 0 : 4}
            />
          </TooltipWithTouch>
        </div>
      </div >
    );
  };

  // const renderLabelBetaReport = () => {
  //   return (
  //     <span
  //       className="hrv-report-ml-10 hrv-report-px-6 hrv-report-fs-12 hrv-report-py-2 hrv-report-text-color-blue-6 hrv-report-border-4 hrv-report-fw-500"
  //       style={{ backgroundColor: "#E1F3FE" }}
  //     >
  //       {t("Beta")}
  //     </span>
  //   );
  // };

  const renderLabelNewReport = () => {
    return (
      <span
        className="hrv-report-ml-8 hrv-report-px-6 hrv-report-fs-12 hrv-report-py-2 hrv-report-text-color-white hrv-report-border-4 hrv-report-fw-400"
        style={{ backgroundColor: "#DC2828" }}
      >
        {t("new")}
      </span>
    );
  };

  const checkValidInputNameDashboard = (value) => {
    let error = "";

    if (!value.trim().length) {
      error = t("Not_be_empty");
    }

    if (value.length > 100) {
      error = t("The_report_name_cannot_exceed_100_characters");
    }

    setErrorMgs(error);
    error.length && showMessage(error)
  };

  const handleChangeNameDashboard = async (newReportName) => {
    const { isError } = await EditReport(reportId, { name: newReportName });

    if (isError) {
      console.log("Please try again!!");

      showMessage(t("Đổi tên báo cáo thất bại!"));

    } else {
      showMessage(t(`Đổi tên báo cáo thành công`));

      dispatch(actions.setReportName(newReportName));
    }
  };

  const handleToggleFavoriteBtnItem = async (isToggle) => {
    const { isError } = await EditReport(reportId, { isFavourite: !isToggle });

    if (isError) {
      console.log("Please try again!!");
    } else {
      showMessage(
        t(`${isToggle ? t("Đã gỡ khỏi yêu thích") : t("Đã thêm vào yêu thích")}`),
      );

      dispatch(actions.toggleIsFavourite(!isToggle));
    }
  }

  const checkActiveExportButton = () => {
    if (isAccessReportDiscount && !isEmpty(discount)) {
      if (!exportId && dataLink?.length) return false
    }

    if (isAccessReportDiscount && isEmpty(discount)) return true

    if (exportId || !dataLink?.length) return true
  }

  return (
    <div className="hrv-report-mb-16 hrv-report-xs-px-16 hrv-report-md-px-0">
      <div className="hrv-report-d-flex hrv-report-items-center hrv-report-justify-between">
        <div className="hrv-report-d-flex hrv-report-items-center hrv-report-detail-name" style={{
          flex: 1
        }}>
          <Button
            variant="default-default"
            onClick={backHandler}
            rightIcon={svgIcon}
            iconSpacing={0}
            w="32px"
          />

          <div style={{
            flex: 1,
            display: 'flex'
          }}>
            {isSystemReport ? <Box alignItems="center" display="flex" textStyle='h4' ml={8}>
              <HLimitLongString
                customClass="hrv-report-mb-0 hrv-report-fw-600 hrv-report-fs-18"
                text={t(reportName)}
                lengthText={155}
                placement="bottom"
              />
            </Box> : <HEditable
              isEdit={false}
              placeholder={t("Nhập tên báo cáo")}
              value={reportName}
              onHandleClickOutside={(value) => {
                handleChangeNameDashboard(value)
              }}
              onHandleClickInText={() => { }}
              className="hrv-report-ml-8"
              errorMgs={errorMgs}
              onValidate={checkValidInputNameDashboard}
            />}

            <div style={{
              display: "flex",
              alignItems: "center"
            }}>
              {isAddNewReport ?
                renderLabelNewReport() : null}

              <TooltipWithTouch label={!isFavourite ? "Thêm vào yêu thích" : "Bỏ yêu thích"}
                placement="top-end"
                hasArrow
              >
                <Button
                  variant="default-tertiary" onClick={() => {
                    handleToggleFavoriteBtnItem(isFavourite);
                  }}
                  ml={4}
                  rightIcon={isFavourite ? svgStarredActive : svgStarredDisable}
                  iconSpacing={0}
                  w={'32px'}
                />
              </TooltipWithTouch>
            </div>
          </div>
        </div>

        {isAccessReportDiscount ? "" : renderMobile()}

        {renderDesktop()}
      </div>
    </div>
  );
}

const svgIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M14.8627 3.225L13.3794 1.75L5.1377 10L13.3877 18.25L14.8627 16.775L8.0877 10L14.8627 3.225Z"
      fill="#212121"
    />
  </svg>
);

const svgMoreIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99967 8.3335C4.08301 8.3335 3.33301 9.0835 3.33301 10.0002C3.33301 10.9168 4.08301 11.6668 4.99967 11.6668C5.91634 11.6668 6.66634 10.9168 6.66634 10.0002C6.66634 9.0835 5.91634 8.3335 4.99967 8.3335ZM14.9997 8.3335C14.083 8.3335 13.333 9.0835 13.333 10.0002C13.333 10.9168 14.083 11.6668 14.9997 11.6668C15.9163 11.6668 16.6663 10.9168 16.6663 10.0002C16.6663 9.0835 15.9163 8.3335 14.9997 8.3335ZM9.99967 8.3335C9.08301 8.3335 8.33301 9.0835 8.33301 10.0002C8.33301 10.9168 9.08301 11.6668 9.99967 11.6668C10.9163 11.6668 11.6663 10.9168 11.6663 10.0002C11.6663 9.0835 10.9163 8.3335 9.99967 8.3335Z"
      fill="#4B5563"
    />
  </svg>
);

const svgDropdownIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.175 6.91248L10 10.7291L13.825 6.91248L15 8.08748L10 13.0875L5 8.08748L6.175 6.91248Z"
      fill="#4B5563"
    />
  </svg>
);

const svgRefresh = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7041 5.29171C13.4957 4.08337 11.8374 3.33337 9.99574 3.33337C6.3124 3.33337 3.3374 6.31671 3.3374 10C3.3374 13.6834 6.3124 16.6667 9.99574 16.6667C13.1041 16.6667 15.6957 14.5417 16.4374 11.6667H14.7041C14.0207 13.6084 12.1707 15 9.99574 15C7.2374 15 4.99574 12.7584 4.99574 10C4.99574 7.24171 7.2374 5.00004 9.99574 5.00004C11.3791 5.00004 12.6124 5.57504 13.5124 6.48337L10.8291 9.16671H16.6624V3.33337L14.7041 5.29171Z"
      fill="#4B5563"
    />
  </svg>
);

const svgStarredDisable = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M18.3337 8.11683L12.342 7.60016L10.0003 2.0835L7.65866 7.6085L1.66699 8.11683L6.21699 12.0585L4.85033 17.9168L10.0003 14.8085L15.1503 17.9168L13.792 12.0585L18.3337 8.11683ZM10.0003 13.2502L6.86699 15.1418L7.70033 11.5752L4.93366 9.17516L8.58366 8.8585L10.0003 5.50016L11.4253 8.86683L15.0753 9.1835L12.3087 11.5835L13.142 15.1502L10.0003 13.2502Z"
      fill="#4B5563"
    />
  </svg>
);

const svgStarredActive = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.0003 14.8085L15.1503 17.9168L13.7837 12.0585L18.3337 8.11683L12.342 7.6085L10.0003 2.0835L7.65866 7.6085L1.66699 8.11683L6.21699 12.0585L4.85033 17.9168L10.0003 14.8085Z"
      fill="#F59F0A"
    />
  </svg>
);