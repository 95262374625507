import { memo, useEffect, useState } from "react";
import { useTrans } from "locales/hook";
import dayjs from "components/Dayjs";

import { ReportDatePicker } from "components/PanelFilter/components";
import HCheckbox from "components/HCheckbox";

import useWindowDimensions from "hooks/useWindowDimension";

import { dayAdd } from "helpers/chartutils";
import { GetDateShowType } from "helpers/date";
import { getAdjustedComparisonDates, isEmpty } from "helpers/helpers";
import { SkeletonText } from "@chakra-ui/react";

function DashboardPanelFilter(props) {
  const {
    onChangeDate,
    startdate,
    enddate,
    compareDateEnd,
    compareDateStart,
    onChangeCompareDate,
    onChangeLabelCompareDate,
    optionCompare,
    autoComplete,
    autoCompleteSelected,
    onHandleOnChangeFilter,
    isLoading,
    isConfigMode
  } = props;

  const { width } = useWindowDimensions();
  const t = useTrans();

  const [rangesPrevious, setRangesPrevious] = useState<any>();


  useEffect(() => {
    const rangesPrevious = handlePreviousPeriodByRanges(startdate, enddate);
    // ! Thay đổi lại bộ range bởi vì khi ngày thay đổi thì bộ option range của kỳ sẽ thay đổi => không cố định mà sẽ flexible theo bộ lọc ngày chuẩn
    setRangesPrevious(rangesPrevious);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startdate, enddate]);


  const handleOnChangeDate = (start, end, label) => {
    // ! Thay đổi ngày lọc
    onChangeDate && onChangeDate(start, end, label);

    if (!optionCompare.length) return;

    if (optionCompare === t("custom") || optionCompare === t("noCompare")) {
      return;
    } else {
      onChangeLabelCompareDate && onChangeLabelCompareDate(t("previousPeriod"));

      const rangesPrevious = handlePreviousPeriodByRanges(start, end)

      onChangeCompareDate &&
        onChangeCompareDate(
          dayjs(rangesPrevious[t("previousPeriod")][0], "DD/MM/YYYY"),
          dayjs(rangesPrevious[t("previousPeriod")][1], "DD/MM/YYYY"),
        );
    }
  };

  const handlePreviousPeriodByRanges = (newStartDate, newEndDate) => {
    let { duration: diffCounts, type: dateShowType } = GetDateShowType(
      newStartDate,
      newEndDate,
    );

    let newRangesPrevious = {
      [t("noCompare")]: [undefined, undefined],
      [t("previousPeriod")]: [
        dayjs(dayAdd(newStartDate, diffCounts * -1, dateShowType)),
        dayjs(dayAdd(newEndDate, diffCounts * -1, dateShowType)),
      ],
      [t("previousYear")]: [
        dayjs(newStartDate).subtract(1, "year"),
        dayjs(newEndDate).subtract(1, "year"),
      ],
    };

    const { start, end } = getAdjustedComparisonDates(newStartDate, newEndDate, diffCounts, dateShowType)

    newRangesPrevious[t("previousPeriod")] = [
      start,
      end
    ]

    return newRangesPrevious;
  }

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: "100%",
          }}
          className="hrv-report-skeleton-full hrv-report-my-16"
        >
          <SkeletonText noOfLines={1} skeletonHeight='30px' />
        </div>
      ) : (
        <div
          id="report_detail_header"
          className="hrv-report-mt-16 hrv-report-whitebox-no-bs hrv-report-lg-pb-8 hrv-report-pb-16"
          style={{
            position: "sticky",
            top: isConfigMode ? 55 : -1,
            height: "fit-content",
            zIndex: 123,
          }}
        >
          <div className="hrv-report-row">
            {/* Group Datepicker */}
            <div className="
              hrv-report-row
              hrv-report-col-12
              hrv-report-min2xl-w-auto
              hrv-report-min2xl-mb-0
              hrv-report-mb-8
            ">
              <div className="hrv-report-md-pr-4 hrv-report-col-12 hrv-report-md-col-6 hrv-report-md-mb-0 hrv-report-mb-8 hrv-report-min2xl-w-auto">
                <ReportDatePicker
                  dateStart={startdate}
                  dateEnd={enddate}
                  isShowIcon={true}
                  placement="right"
                  autoApply={width < 856}
                  onCallback={(start, end, label) => {
                    handleOnChangeDate && handleOnChangeDate(start, end, label);
                  }}
                />
              </div>

              {!isEmpty(rangesPrevious) && (
                <div className="hrv-report-md-pl-4 hrv-report-col-12 hrv-report-md-col-6 hrv-report-min2xl-w-auto">
                  <ReportDatePicker
                    dateStart={compareDateStart}
                    dateEnd={compareDateEnd}
                    customText={`${t("So sánh")}: `}
                    ranges={rangesPrevious}
                    isComparePeriod={true}
                    placement="right"
                    onCallback={(start, end, label) => {
                      if (label !== undefined) {
                        onChangeLabelCompareDate &&
                          onChangeLabelCompareDate(label);
                      }

                      if (label === t("noCompare")) {
                        onChangeCompareDate &&
                          onChangeCompareDate(undefined, undefined, label);
                      } else {
                        onChangeCompareDate &&
                          onChangeCompareDate(start, end, label);
                      }
                    }}
                    isShowIcon={false}
                    autoApply={width < 856}
                  />
                </div>
              )}

            </div>

            <span className="hrv-report-min2xl-d-block hrv-report-d-none hrv-report-wrapper-dashboardv2 hrv-report-divider"></span>

            {/* Group Filter */}
            <div className="
              hrv-report-row
              hrv-report-col-12
              hrv-report-min2xl-col-0
            ">
              <div
                key={`option_to_loc_id`}
                className="hrv-report-min2xl-w-auto hrv-report-md-pr-4 hrv-report-col-12 hrv-report-md-col-6 hrv-report-md-mb-0 hrv-report-mb-8"
              >
                <HCheckbox
                  name="displayname_vi"
                  valueName="value"
                  defaultValue={autoCompleteSelected["LocationId"]}
                  onChange={(value) => {
                    onHandleOnChangeFilter("LocationId", value);
                  }}
                  options={autoComplete["LocationId"]}
                  nameField={`${t("Chi nhánh bán")}`}
                  fullWidth
                  isShowNameField={true}
                  isMultiple
                  useEmptyForGetAll
                  showApplyButton
                  widthFixed={276}
                  showButtonAll
                  multipleType="label"
                  size="m"
                  showSearch
                />
              </div>

              <div
                key={`option_channel`}
                className="hrv-report-min2xl-w-auto hrv-report-md-pl-4 hrv-report-col-12 hrv-report-md-col-6"
              >
                <HCheckbox
                  isMultiple={true}
                  name="displayname_vi"
                  valueName="value"
                  defaultValue={autoCompleteSelected["SourceName"]}
                  onChange={(value) => {
                    onHandleOnChangeFilter("SourceName", value);
                  }}
                  options={autoComplete["SourceName"]}
                  useEmptyForGetAll={true}
                  isShowNameField={false}
                  nameField={`${t("Kênh bán")}`}
                  fullWidth
                  showApplyButton
                  showButtonAll={true}
                  isTranslation={false}
                  size="m"
                  multipleType="label"
                  showSearch
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default memo(DashboardPanelFilter);
