import { Line } from "react-chartjs-2";
import * as ChartUtils from "helpers/chartutils";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";

import { GetDateShowType } from "helpers/date";

import {
  GetCurrencyFormater,
  PointsToCommas,
  formatDate,
  formatNumber,
} from "helpers/helpers";
import { useRef } from "react";
import { useTrans } from "locales/hook";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
);

ChartUtils.RegisterTooltipPositioner();

function LineChart(props) {
  const {
    dataChart,
    configHeight,
    startdate,
    enddate,
    dataCurrent,
    dataPrevious,
    compareDateStart,
    compareDateEnd,
    unit,
    optionCompare,
    groupBy,
  } = props;

  const plugins = [ChartUtils.hoverLine, ChartUtils.addGradientBgToDataset(0)];
  const { type } = GetDateShowType(startdate, enddate);
  const refChart = useRef<any>(null);
  const t = useTrans();

  let formater = GetCurrencyFormater();

  let dataFormat = unit?.length ? unit : "currency";

  // TODO: tạm thời hard là money khi nào có tính năng chọn measure sẽ tiếp tục cấu hình
  // const measure = measures.find((m) => m.isSelected);

  // if (measure) {
  //   dataFormat =
  //     measure.measureName === "DiscountValueInPercent"
  //       ? "percentage"
  //       : measure.isMoney
  //       ? "currency"
  //       : "number";
  // }

  const checkCurrentDateAndPreviousDateIsTheSameYear = (array) => {
    return !array.every((v) => v === array[0]);
  };

  const chartDataFormat = (value: any, format: string) => {
    if (value === "--") return '--';

    switch (format) {
      case "currency":
        return PointsToCommas(
          formater.format(value),
        )
      case "number":
        return formatNumber(value);

      case "percentage":
        return formatNumber(value) + "%"

      default:
        return formatNumber(value) + " " + dataFormat
    }
  }

  let isShowYear =
    compareDateStart || compareDateEnd
      ? checkCurrentDateAndPreviousDateIsTheSameYear([
        startdate.format("YYYY"),
        enddate.format("YYYY"),
        compareDateEnd.format("YYYY"),
        compareDateStart.format("YYYY"),
      ])
      : false;

  const currentDataSet = dataChart.datasets[0];
  const previousDataSet = dataChart.datasets[1] || [];

  const externalTooltipHandler = (context, args) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = ChartUtils.getOrCreateTooltip(chart);

    const typeDate = () => {
      return type === "hour"
        ? `DD/MM${isShowYear ? "/YYYY" : ""}, HH:mm`
        : type === "month"
          ? `MM/YYYY`
          : type === "year"
            ? "YYYY"
            : `DD/MM${isShowYear ? "/YYYY" : ""}`;
    };



    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    let indexData = tooltip.dataPoints[0].dataIndex;

    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      titleLines.forEach((title) => {
        indexData = dataChart.labels.findIndex((l) => l === title);
      });

      const tableBody = document.createElement("tbody");
      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = "0px";

      const labelTd = document.createElement("td");
      labelTd.style.borderWidth = "0px";
      labelTd.style.padding = "12px 16px";
      const totalTd = document.createElement("td");
      totalTd.style.borderWidth = "0px";
      totalTd.style.padding = "12px 16px";

      const dateCurrentByIndex = dataCurrent[indexData]?.date || "";
      const datePreviousByIndex = dataPrevious[indexData]?.date || "";
      const dataCurrentByIndex = currentDataSet?.data?.length ? (currentDataSet.data[indexData] ?? "--") : [];
      const dataPreviousByIndex = previousDataSet?.data?.length ? (previousDataSet.data[indexData] ?? "--") : []

      if (["Date", "Hour", "Day", "Month", "Year"].includes(groupBy)) {

        // ! Xử lý Tooltip Current
        if (dataCurrent.length) {
          const labelCurrentContainer = document.createElement("div");

          labelCurrentContainer.classList.add("hrv-report-d-flex");
          labelCurrentContainer.classList.add("hrv-report-mb-10");

          labelCurrentContainer.innerHTML = `
            <div class="hrv-report-d-flex hrv-report-items-center">
              <div class="hrv-report-border-blue-custom"></div>
              <div class="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400">${formatDate(
            dateCurrentByIndex,
            typeDate(),
          )}</div>
            </div>
          `;

          totalTd.innerHTML = `
            <div class="hrv-report-mb-10 hrv-report-fs-14 hrv-report-fw-400 hrv-report-text-right">
            ${chartDataFormat(dataCurrentByIndex, dataFormat)}</div>

            ${optionCompare !== t("noCompare") && dataPrevious.length
              ? `
            <div class="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400 hrv-report-text-right">${chartDataFormat(dataPreviousByIndex, dataFormat)}</div>`
              : ""
            }
          `;

          labelTd.appendChild(labelCurrentContainer);
        }

        // ! Xử lý Tooltip Previous
        if (optionCompare !== t("noCompare") && dataPrevious.length) {
          const labelPreviousContainer = document.createElement("div");

          labelPreviousContainer.classList.add("hrv-report-d-flex");
          labelPreviousContainer.innerHTML = `
            <div class="hrv-report-d-flex hrv-report-items-center">
              <div class="hrv-report-border-dashed-blue-custom"></div>
              <div class="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400">${formatDate(
            datePreviousByIndex,
            typeDate(),
          )}</div>
            </div>
          `;

          labelTd.appendChild(labelPreviousContainer);
        }
      } else {
        // ! Xử lý Tooltip Current
        if (dataChart.labels.length) {
          const labelCurrentContainer = document.createElement("div");

          labelCurrentContainer.classList.add("hrv-report-d-flex");
          labelCurrentContainer.classList.add("hrv-report-mb-10");

          labelCurrentContainer.innerHTML = `
            <div class="hrv-report-d-flex hrv-report-items-center">
              <div class="hrv-report-border-blue-custom"></div>
              <div class="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400">${dataChart.labels[indexData]}</div>
            </div>
          `;

          totalTd.innerHTML = `
            <div class="hrv-report-mb-10 hrv-report-fs-14 hrv-report-fw-400 hrv-report-text-right">${chartDataFormat(dataChart.datasets[0].data[indexData], dataFormat)}</div>

            ${optionCompare !== t("noCompare") && dataPrevious.length
              ? `
            <div class="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400 hrv-report-text-right">${chartDataFormat(previousDataSet.data[indexData], dataFormat)}</div>`
              : ""
            }
          `;

          labelTd.appendChild(labelCurrentContainer);
        }
      }

      tr.appendChild(labelTd);
      tr.appendChild(totalTd);

      tableBody.appendChild(tr);

      const tableRoot = tooltipEl.querySelector("table");

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableBody);
    }

    // ! Cấu hình position tooltip
    const { offsetTop: positionY } = chart.canvas;
    const { caretX } = chart.tooltip;

    tooltipEl.style.opacity = 1;

    const isFloatRight =
      caretX + tooltipEl.offsetWidth >
      refChart.current.clientWidth - caretX + 100;

    if (isFloatRight) {
      // Bên phải
      tooltipEl.style.left = caretX - tooltipEl.clientWidth + "px";
    } else {
      // Bên trái
      tooltipEl.style.left = caretX + 25 + "px";
    }

    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
  };

  let options = ChartUtils.GetLineConfig(
    dataChart,
    externalTooltipHandler,
  ) as any;

  if (type === "hour") {
    options.scales.x.ticks.callback = (val, index) => {
      return dataChart.labels[index];
    };
  }

  if (!dataCurrent.length && !dataPrevious.length) {
    options.scales.y.suggestedMin = 0;
    options.scales.y.suggestedMax = 50;
  } else {
    let newCurrent = [...(currentDataSet?.data || [])];
    let newPrevious = [...(previousDataSet?.data || [])];
    let maxValue = Math.max(...newCurrent, ...newPrevious);
    if (maxValue < 10) {
      options.scales.y.suggestedMax = 10;
    }
  }

  return (
    <div
      style={{
        width: "100%",
        height: configHeight,
      }}
      ref={refChart}
    >
      <Line
        datasetIdKey="revenue"
        data={dataChart}
        options={options}
        plugins={plugins}
      />
    </div>
  );
}

export default LineChart;
