import { useTrans } from "locales/hook";
import UpgragePlanSvg from "assets/images/upgrade-plan.svg";
import { Button } from "@chakra-ui/react";
import { capitalizeFirstLetter, queryString } from "helpers/helpers";

// * Khi mở 1 báo cáo mới lưu ý thêm 1 report bị check gói tại đây
const REPORTS: Record<string, Array<string>> = {
    inventory: [
        "Báo cáo tồn kho",
        "Bảng kê xuất nhập tồn",
        "Báo cáo nhập hàng",
        "Báo cáo sắp hết hàng",
    ],
    customers: [
        "Khách mới, khách cũ",
        "Theo nhóm tuổi, giới tính, vị trí địa lý",
        "Khách hàng theo phân khúc RFM"
    ],
    summary: [
        "Báo cáo tồn kho",
        "Bảng kê xuất nhập tồn",
        "Khách mới, khách cũ",
        "Theo nhóm tuổi, giới tính, vị trí địa lý",
        "Khách hàng theo phân khúc RFM"
    ]
};

interface IProps {
    packages: Array<string>
    plans: Array<string>
}

function UpgradePlanSuggest(props: IProps) {
    const { packages, plans } = props;
    const t = useTrans();
    const shop: any = queryString.shop;

    const topic = () => {
        return packages.length > 1 ? packages.map(y => `"${t(y)}"`).join(" & ") : `"${t(packages[0])}"`
    };

    const packageNeedToUpgrade = () => {
        const PLAN_LEVEL: Record<number, string> = {
            0: "omnistandard",
            1: "pro",
            2: "grow",
            3: 'scale',
        };

        const transPlanToLevel: any = plans.map((planName) => {
            const level = Object.values(PLAN_LEVEL).findIndex(value => planName === value)

            return level;
        });

        return PLAN_LEVEL[Math.max(...transPlanToLevel)]
    }

    return (
        <div
            style={{
                minHeight: 324,
            }}
            className="
            hrv-report-w-full 
            hrv-report-row
            hrv-report-items-center
            hrv-report-py-40
            "
        >
            <div className="hrv-report-d-flex hrv-report-md-justify-end hrv-report-justify-center hrv-report-col-12 hrv-report-md-col-6 hrv-report-lg-pr-36 hrv-report-md-pr-20 hrv-report-mb-20 hrv-report-lg-mb-0 hrv-report-px-20 hrv-report-px-md-0" >
                <UpgragePlanSvg />
            </div>

            <div className="hrv-report-flex-col hrv-report-d-flex hrv-report-items-center hrv-report-md-items-start hrv-report-col-12 hrv-report-md-col-6 hrv-report-lg-pl-36 hrv-report-md-pl-20 hrv-report-px-20 hrv-report-px-md-0">
                <h5
                    className="hrv-report-mb-16 hrv-report-fs-16 hrv-report-fw-600 hrv-report-text-center hrv-report-md-text-left"
                >
                    {t("Nâng cấp lên gói")}{" "}
                    {capitalizeFirstLetter(packageNeedToUpgrade())}{" "}
                    {t("để xem báo cáo về")}{" "}
                    {topic()}
                </h5>

                <>
                    {packages.length > 1 ? <>
                        {REPORTS['summary'].map((reportName, idx) => {
                            return <span
                                key={idx}
                                className="hrv-report-d-flex hrv-report-items-center hrv-report-mb-8"
                            >
                                <span
                                    className="hrv-report-mr-10"
                                >
                                    {svgCheck}
                                </span>
                                <p
                                    className="hrv-report-mb-0"
                                >
                                    {t(reportName)}
                                </p>
                            </span>
                        })}
                    </> :
                        <>
                            {
                                Object.keys(REPORTS).map((key: string, idx: string | number) => {
                                    return <>
                                        {packages.includes(key) ?
                                            <>
                                                {REPORTS[key].map((reportName, idx) => {
                                                    return <span
                                                        key={idx}
                                                        className="hrv-report-d-flex hrv-report-items-center hrv-report-mb-8"
                                                    >
                                                        <span
                                                            className="hrv-report-mr-10"
                                                        >
                                                            {svgCheck}
                                                        </span>
                                                        <p
                                                            className="hrv-report-mb-0"
                                                        >
                                                            {t(reportName)}
                                                        </p>
                                                    </span>
                                                })}
                                            </>
                                            : <></>
                                        }
                                    </>
                                })
                            }
                        </>
                    }
                </>

                <Button
                    variant='default-primary'
                    mt={4}
                    style={{
                        display: 'inline-flex'
                    }}
                    size={"l"}
                    onClick={() => {
                        window.open(
                            `https://${shop}/admin/settings/billings/plan`,
                        );
                    }}
                >
                    {t("Nâng cấp gói")}
                </Button>
            </div>
        </div>
    )
}

export default UpgradePlanSuggest;

const svgCheck = <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M7.00065 0.333008C3.32065 0.333008 0.333984 3.31967 0.333984 6.99968C0.333984 10.6797 3.32065 13.6663 7.00065 13.6663C10.6807 13.6663 13.6673 10.6797 13.6673 6.99968C13.6673 3.31967 10.6807 0.333008 7.00065 0.333008ZM7.00065 12.333C4.06065 12.333 1.66732 9.93968 1.66732 6.99968C1.66732 4.05968 4.06065 1.66634 7.00065 1.66634C9.94065 1.66634 12.334 4.05968 12.334 6.99968C12.334 9.93968 9.94065 12.333 7.00065 12.333ZM10.0607 4.05301L5.66732 8.44634L3.94065 6.72634L3.00065 7.66634L5.66732 10.333L11.0007 4.99968L10.0607 4.05301Z" fill="#16A249" />
</svg>