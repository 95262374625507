import { useEmbedApp, useTrans } from "@haravan/reactapp";
import { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from "react";

import {
    Box, Button, Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Input,
    SkeletonText,
} from "@chakra-ui/react";

import {
    svgFullscreen, svgClone, svgDeleteRed, svgArrow, svgEdit, svgConfig, svgSmallScreen, svgReset
} from ".";
import { useGTM } from "hooks/useGTM";

const HButton: any = Button;

export const DashboardHeader = forwardRef((props: any, ref) => {
    const {
        onClickConfig,
        onClickFullscreen,
        isFullScreen,
        isConfigMode,
        dashboardList,
        defaultTemplateDashboard,
        isLoading,
        onHandleOpenModalConfirm,
        onHandleCloneDashboard,
        onHandleChangeNameDashboard,
    } = props;

    const { attributes, detectClass } = useGTM({
        enabled: true,
        variables: {
            "ut-screen": "dashboard",
            "ut-name": 'fullscreen'
        }
    });

    const t = useTrans();

    const [isChangeNameMode, setIsChangeNameMode] = useState(false);
    const [valueName, setValueName] = useState("");
    const [errorMgs, setErrorMgs] = useState("");

    const { showMessage } = useEmbedApp();

    useImperativeHandle(ref, () => ({
        reset() {
            setIsChangeNameMode(false);
        },
        checkValidation() {
            if (errorMgs.length) {
                setIsChangeNameMode(false);
                setValueName(defaultTemplateDashboard.name);
                setErrorMgs("");
            }

            setIsChangeNameMode(false);
        }
    }));

    useEffect(() => {
        setIsChangeNameMode(false);
    }, [isFullScreen]);

    useEffect(() => {
        if (errorMgs.length) {
            showMessage(errorMgs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMgs]);

    const contentTransaction = (dashboard) => {
        const { dashboardId, orderNumber } = dashboard;

        const actionsList = [
            {
                action_name: "fullScreen",
                action_icon: svgFullscreen,
                action_handle: () => {

                    onClickFullscreen && onClickFullscreen();
                },
                button_variant: "default-tertiary",
                isActive: true,
                className: detectClass,
                attributes
            },
            {
                action_name: "Replication",
                action_icon: svgClone,
                button_variant: "default-tertiary",
                isActive: true,
                action_handle: () => {
                    onHandleCloneDashboard && onHandleCloneDashboard(dashboardId);
                },
                className: "",
                attributes: ""
            },
            {
                action_name: "Delete",
                action_icon: svgDeleteRed,
                button_variant: "critical-tertiary",
                action_handle: () => {
                    onHandleOpenModalConfirm &&
                        onHandleOpenModalConfirm(dashboardId, "delete");
                },
                isActive: dashboardList.length > 1 && orderNumber !== 1,
                className: "",
                attributes: ""
            },
        ];

        return (
            <>
                {actionsList.map((opt, idx) => {
                    return (
                        <Fragment key={idx}>
                            {opt.action_name === t("Delete") && (
                                <div
                                    className="hrv-report-border-top-black-7 hrv-report-mb-4 hrv-report-mt-2"
                                    style={{
                                        marginRight: "-8px",
                                        marginLeft: "-8px",
                                    }}
                                ></div>
                            )}
                            {
                                opt.isActive && <MenuItem as={Box}>
                                    <HButton
                                        variant={opt.button_variant}
                                        onClick={() => {
                                            opt.isActive && opt.action_handle();
                                        }}
                                        leftIcon={opt.action_icon}
                                        isDisabled={!opt.isActive}
                                        style={{
                                            width: "100%",
                                            justifyContent: "start",
                                            fontWeight: 400,
                                        }}
                                        className={opt.className}
                                        {...opt.attributes}
                                    >
                                        {t(opt.action_name)}
                                    </HButton>
                                </MenuItem>
                            }
                        </Fragment>
                    );
                })}
            </>
        );
    };

    // const handleClickAddDashboard = async () => {
    //     onHandleClickAddDashboard && onHandleClickAddDashboard();
    //     setIsChangeNameMode(false);
    // };

    const checkValidInputNameDashboard = (value) => {
        let error = "";

        if (!value.trim().length) {
            error = t("Not_be_empty");
        }

        if (value.length > 100) {
            error = t("The_dashboard_name_cannot_exceed_100_characters");
        }

        if (dashboardList
            .filter((t) => t.dashboardId !== defaultTemplateDashboard.dashboardId)
            .findIndex((y) => y.name === value.trim()) !== -1) {
            error = t("The_dashboard_name_already_exists");
        }

        setErrorMgs(error);
    };

    const handleChangeNameDashboard = () => {
        setIsChangeNameMode(false);

        const tabId = defaultTemplateDashboard.dashboardId;

        onHandleChangeNameDashboard &&
            onHandleChangeNameDashboard(
                tabId,
                valueName.trim()
            );
    };

    return (
        <div
            className="
            hrv-report-mt-16 hrv-report-d-flex hrv-report-items-center hrv-report-justify-between hrv-report-md-mx-0 hrv-report-mx-16"
        >
            {isLoading ? (
                <div
                    style={{
                        width: "100%",
                    }}
                    className="hrv-report-skeleton-full hrv-report-mb-16"
                >
                    <SkeletonText noOfLines={1} skeletonHeight='30' />
                </div>
            ) : (
                <>
                    <div
                        style={{
                            width: "100%",
                        }}
                    >
                        {isChangeNameMode || isConfigMode ? (
                            <div
                                style={{
                                    height: 40,
                                    flex: 1,
                                }}
                            >
                                <Input
                                    placeholder={t("Enter_dashboard_name")}
                                    value={valueName}
                                    onChange={(event) => {
                                        const searchKey = event.target.value;

                                        setValueName(searchKey);
                                        checkValidInputNameDashboard(searchKey);
                                    }}
                                    onBlur={() => {
                                        // ! Check valid Input
                                        if (!errorMgs.length) {
                                            handleChangeNameDashboard();
                                        }
                                    }}
                                    style={{
                                        fontWeight: 600,
                                        fontSize: 18,
                                        width: "100%",
                                    }}
                                    variant="autocomplete"
                                    className={`${errorMgs.length ? "has-error" : ""}`} />

                            </div>
                        ) : (
                            <div className="hrv-report-d-flex hrv-report-items-center hrv-report-dashboard-name" style={{
                                width: "fit-content",
                                flex: 1
                            }} onClick={() => {
                                setIsChangeNameMode(true);
                                setValueName(defaultTemplateDashboard.name);
                            }}>
                                <span
                                    className="hrv-report-mb-0 hrv-report-fs-18 hrv-report-fw-600 hrv-report-text-color-black-6"
                                    style={{
                                        lineHeight: "40px",
                                        overflow: 'hidden',
                                        display: "-webkit-box",
                                        WebkitLineClamp: 1,
                                        textOverflow: "ellipsis",
                                        WebkitBoxOrient: "vertical",
                                    }}

                                >
                                    {defaultTemplateDashboard.name}
                                </span>

                                <span className="hrv-report-ml-8">{svgEdit}</span>
                            </div>
                        )}
                    </div>

                    {!isChangeNameMode && !isConfigMode && (
                        <div
                            className="hrv-report-md-d-flex hrv-report-d-none hrv-report-items-center hrv-report-justify-end"
                            style={{
                                width: 400,
                            }}
                        >
                            {!isConfigMode && !isFullScreen && (
                                <Button
                                    variant={'default-default'}
                                    iconSpacing={2}
                                    leftIcon={svgConfig}
                                    onClick={() => {
                                        setIsChangeNameMode(true);

                                        setValueName(defaultTemplateDashboard.name);

                                        onClickConfig && onClickConfig();
                                    }}
                                    className="user-tracker"
                                    ut-screen={"dashboard"}
                                    ut-name={'adjust'}
                                >
                                    {t("adjust")}
                                </Button>
                            )}

                            {!isConfigMode && !isFullScreen && (
                                <Box ml={4}>
                                    <Menu placement="bottom-end" size="m">
                                        <MenuButton
                                            as={Button}
                                            variant={'default-default'}
                                            rightIcon={svgArrow}
                                            iconSpacing={2}
                                        >
                                            {t("actions")}
                                        </MenuButton>

                                        <MenuList>
                                            {contentTransaction(defaultTemplateDashboard)}
                                        </MenuList>
                                    </Menu>
                                </Box>

                            )}

                            {!isConfigMode && isFullScreen && (
                                <Button
                                    variant={'default-default'}
                                    leftIcon={svgSmallScreen}
                                    className="hrv-report-ml-16"
                                    iconSpacing={2}
                                    onClick={() => {
                                        onClickFullscreen && onClickFullscreen();
                                    }}
                                >
                                    {t("MinimizeScreen")}
                                </Button>
                            )}
                        </div>
                    )}

                    {isConfigMode && (
                        <div
                            className="hrv-report-d-flex hrv-report-justify-end hrv-report-items-center hrv-report-cursor-pointer hrv-report-reset-dashboard-button"
                            onClick={() => {
                                onHandleOpenModalConfirm &&
                                    onHandleOpenModalConfirm(
                                        defaultTemplateDashboard.dashboardId,
                                        "reset"
                                    );
                            }}
                        >
                            <span className="hrv-report-mr-8">{svgReset}</span>

                            <p className="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-600 hrv-report-text-color-gray-9">
                                {t("Reset_default")}
                            </p>
                        </div>
                    )}
                </>
            )}
        </div>
    );
});