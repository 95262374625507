import { useTrans } from "locales/hook";

function HeatmapDescription(props) {
  const { pallete } = props;
  const t = useTrans();

  return (
    <>
      <div
        className="hrv-report-mt-12 hrv-report-row hrv-report-relative"
        style={{
          borderRadius: 2,
          height: 12,
          overflow: "hidden",
        }}
      >
        {pallete.map((level, idx) => {
          return (
            <div
              key={`${idx}_level_color`}
              style={{
                background: level.color,
                width: `calc(100% / ${pallete.length})`,
                left: `calc(100% / ${pallete.length} * ${idx})`,
                height: 12,
                top: 0,
              }}
              className="hrv-report-absolute"
            ></div>
          );
        })}
      </div>

      <div className=" hrv-report-row hrv-report-justify-between">
        {pallete.map((level, idx) => {
          return (
            <>
              {level.isShowText && (
                <p
                  style={{
                    margin: 0,
                  }}
                  key={idx}
                  className="hrv-report-mt-4 hrv-report-fs-12 hrv-report-text-color-gray-9"
                >
                  {t(level.name)}
                </p>
              )}
            </>
          );
        })}
      </div>
    </>
  );
}

export default HeatmapDescription;
