
/**
 * Search và Chọn giá trị trong Dropdown (Chỉ hỗ trợ Single value)
 * @param props 
 * @returns 
 * @author: Mạnh Đạt
 */

import { Box, Input, InputGroup, InputRightElement, Menu, MenuButton, MenuGroup, MenuItem, MenuList, useDisclosure, useOutsideClick } from "@chakra-ui/react";
import { useTrans } from "locales/hook";
import { separateItemToGroup, slugify } from "helpers/helpers";
import { EffectCallback, useEffect, useRef, useState } from "react";
import useDebouncedCallback from "hooks/useDebounceCallback";

interface IProps {
    defaultSelect: any; // Giá trị mặc định được chọn trước đó
    options: Array<any>; // Danh sách giá trị sẽ hiển thị trong dropdown
    inputName: string; // Tên của input
    name: string; // Field name để hiển thị
    value: string; // field value để get
    onHandleChange: Function; // Khi Click vào item
    disabled?: boolean // Input có bị disabled hay không ?
};

function SearchableDropdown(props: IProps) {
    const t = useTrans();
    const { options, inputName, name, value, defaultSelect, onHandleChange, disabled } = props;
    const ref = useRef<any>();
    const refMenuItem = useRef<any>(null);
    const [keyword, setKeyword] = useState(defaultSelect);
    const [defaultOptions, setDefaultOptions] = useState(options);

    const { isOpen, onClose, onToggle } = useDisclosure();

    useOutsideClick({
        ref, handler: () => {
            if (isOpen) {
                onClose();
            }
        }
    });

    const handleChangeDebounced = (value: string) => {
        if (isOpen) {
            if (value?.trim().length) {
                let result: any = [];

                result = options?.filter((y) => {
                    if (t(y[name]) && t(y[name]).length && typeof value !== 'undefined') {
                        return slugify(t(y[name])?.toLowerCase()).includes(
                            slugify(t(value)?.toLowerCase())
                        );
                    } else {
                        return t(y)
                    }
                });

                setDefaultOptions(result);
            } else {
                setDefaultOptions(options);
            }
        } else {
            return;
        }
    };

    const [debouncedCallback] = useDebouncedCallback(handleChangeDebounced, 500, [keyword]);

    useEffect(() => {
        setDefaultOptions(options)
    }, [options])

    const useEffectWhenReceiveNewDefaultValue = (effect: EffectCallback) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return useEffect(effect, [defaultSelect, isOpen])
    }

    useEffectWhenReceiveNewDefaultValue(() => {
        if (defaultSelect.length) {
            const findFilterName = options?.find(y => y[value] === defaultSelect)[name]
            setKeyword(t(findFilterName))
        }
    })

    const handleOnChangeSearch = (value) => {
        setKeyword(value);
    }

    const divideGroup = separateItemToGroup(defaultOptions);

    const handleChange = (result) => {
        onHandleChange && onHandleChange(result);
        onClose();

        setTimeout(() => {
            setDefaultOptions(options);
        }, 100)
    }

    useEffect(() => {
        if (refMenuItem && refMenuItem?.current && isOpen) {
            refMenuItem.current.scrollIntoView({
                behavior: "instant",
                block: "center"
            })
        }
    }, [isOpen]);


    return (
        <div ref={ref}>
            <Menu closeOnSelect variant="selection" matchWidth autoSelect={false} flip isLazy >
                <MenuButton
                    as={Box}
                    onClick={(e) => {
                        if (disabled) return;

                        const findInput: any = document.getElementById(inputName);
                        findInput && findInput.focus();

                        if (!isOpen) {
                            onToggle();

                            return;
                        }
                    }}

                >
                    <InputGroup>
                        <Input
                            id={inputName}
                            value={keyword}
                            autoComplete={"off"}
                            placeholder={t('Chọn bộ lọc')}
                            onChange={(e) => {
                                handleOnChangeSearch(e.target.value)
                                debouncedCallback(e.target.value)
                            }}
                            size="m"
                            disabled={disabled}
                        />

                        <InputRightElement h="full" w={10}>
                            {svgArrow}
                        </InputRightElement>
                    </InputGroup>
                </MenuButton>

                {isOpen ? <MenuList
                    onFocus={() => {
                        const findInput = document.getElementById(inputName);
                        findInput && findInput.focus();
                    }}
                    className="hrv-report-scrollbar_custom"
                >
                    {defaultOptions?.length ? (
                        <>
                            {Object.keys(divideGroup).map((property, index) => {
                                const keyDefined = `option_group_${index}`;

                                return (
                                    <MenuGroup title={t(property)} key={keyDefined}>
                                        {divideGroup[property].map((item, optionId) => {
                                            const keyOptDefined = `option_child_${optionId}`;

                                            return (
                                                <MenuItem
                                                    ref={defaultSelect === item[value] ? refMenuItem : null}
                                                    id={defaultSelect}
                                                    isDisabled={defaultSelect === item[value]}
                                                    key={keyOptDefined}
                                                    value={item[value]}
                                                    onClick={() => {
                                                        handleChange(item[value])
                                                    }}
                                                >
                                                    {t(item[name])}
                                                </MenuItem>
                                            );
                                        })}
                                    </MenuGroup>
                                );
                            })}
                        </>
                    ) : (
                        <>

                            <MenuItem value="empty_component" style={{
                                height: "100%",
                                overflow: "hidden",
                            }}
                                p={0}
                                m={0}
                                background="#ffffff"
                                _hover={{
                                    bg: '#ffffff'
                                }}>
                                <div className="hrv-report-d-flex hrv-report-items-center hrv-report-w-100">
                                    <p className="hrv-report-w-100 hrv-report-text-center hrv-report-mt-15">
                                        {t("no_data")}
                                    </p>
                                </div>
                            </MenuItem>
                        </>
                    )}
                </MenuList> : ""}
            </Menu>
        </div>
    )
}

export default SearchableDropdown;


const svgArrow = (
    <svg
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.87461 1.00002L5.99461 4.88002L2.11461 1.00002C1.72461 0.61002 1.09461 0.61002 0.704609 1.00002C0.314609 1.39002 0.314609 2.02002 0.704609 2.41002L5.29461 7.00002C5.68461 7.39002 6.31461 7.39002 6.70461 7.00002L11.2946 2.41002C11.6846 2.02002 11.6846 1.39002 11.2946 1.00002C10.9046 0.62002 10.2646 0.61002 9.87461 1.00002Z"
            fill="#9CA3B0"
        />
    </svg>
);
