import { useEffect } from "react";

export const useDetectPage = (title: string, tab: string = '', report_name: string = '') => {
  useEffect(() => {
    const _window: any = window;
    const _document: any = document;
    const getDataLayer = _window.dataLayer;
    if (title !== '') {
      let pageTitle = `${title} ~ Haravan ~ Reports`;
      if (_document?.user?.OrgName) {
        pageTitle = `${_document.user.OrgName} ~ ${pageTitle}`;
      }
      document.title = pageTitle;
      getDataLayer.push({
        event: "page_changed",
        page_title: pageTitle,
        tab: tab,
        report_name: report_name
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);
};