import React, { forwardRef, useRef } from "react";

// ! Components
import TableScrollable from "../components/scrollable";
import HPagination from "components/Pagination";
import EmptyTemplate from "components/EmptyTemplate";
import HeatmapDescription from "../components/heatmapDescription";

// ! Hooks
import { useTrans } from "locales/hook";
import { useTableUtils } from "helpers/tableutils";
import { useInView } from "react-intersection-observer";

import {
  HEATMAP_LEVEL,
  useGroupTable,
} from "pages/ReportStandard/context/store/constants";

// ! Helpers
import HeaderTableDefault from "./header";
import BodyTableDefault from "./body";

export const TableByDefault = forwardRef((props: any, ref: any) => {
  const t = useTrans();
  const containerRef = useRef(null);
  const tableRef = useRef<any>(null);

  const [renewSummary, setRenewSummary] = React.useState<any>([]);
  const [refDetectScroll, isDisplay] = useInView({
    threshold: 0.1,
  });

  const {
    data,
    reportNameSystem,
    canReadMACost,
    headers,
    sortValue,
    summary,
    groupBySelected,
    dataLink,
    isHiddenPagination,
    isLoadingTable,
    onChangePage,
    onHandleSortTable,
    heatmapMode,
    benchmark,
    onClickDetailDrilldown,
    isVisibleDrilldown,
    listProductCategoryId,
    dependdata
  } = props;

  const {
    dataHeaders,
    dataRows,
    isReportPNL,
    sortable,
    headingRows,
    subHeadingRows,
    displaySummary
  } = useTableUtils(headers, data, reportNameSystem, groupBySelected);

  React.useEffect(() => {
    if (
      summary !== undefined &&
      headers !== undefined &&
      summary.length &&
      headers.length
    ) {

      if (headers !== undefined && summary !== undefined) {

        const arrLength = headers.length - summary.length;
        if (arrLength > 0) {

          const reSummary =
            [...Array(headers?.length - summary?.length).fill(0), ...summary] ||
            [];

          setRenewSummary(reSummary);
        }
      }
    }
  }, [summary, headers, displaySummary]);

  React.useEffect(() => {
    const { current }: any = containerRef;
    current?.removeEventListener("scroll", handleTableScroll);
    current?.addEventListener("scroll", handleTableScroll);
    return () => {
      current?.removeEventListener("scroll", handleTableScroll);
    };
  }, [containerRef, data]);

  const handleTableScroll = (e) => {
    let table = e.target.getElementsByTagName("table")[0];
    if (e.target.scrollLeft > 0) {
      table.classList.add("hrv-report-table-container__scrolling");
    } else {
      table.classList.remove("hrv-report-table-container__scrolling");
    }
  };


  return (
    <div
      id="hrv-report-table-container"
      style={{
        borderLeft: 0,
        borderRight: 0,
        // overflow: "hidden",
        // height: "0",
      }}
    >
      <div
        ref={tableRef}
      >
        {data !== undefined && data?.length > 0 ? (
          <>
            <TableScrollable
              isVisibleDrilldown={isVisibleDrilldown}
              reportNameSystem={reportNameSystem}
              canReadMACost={canReadMACost}
              dataHeaders={dataHeaders}
              sortValue={sortValue}
              sortable={sortable}
              onHandleSortTable={onHandleSortTable}
              displayVirtual={!isDisplay}
            >
              <div
                className={`${heatmapMode && "hrv-report-heatmap-mode"
                  } hrv-report-table-container hrv-report-relative`}
                ref={containerRef}
              >
                <table
                  className={`${useGroupTable.includes(reportNameSystem)
                    ? "hrv-report-table-bordered"
                    : ""
                    }`}
                >
                  <HeaderTableDefault
                    reportNameSystem={reportNameSystem}
                    canReadMACost={canReadMACost}
                    dataHeaders={dataHeaders}
                    sortValue={sortValue}
                    sortable={sortable}
                    heatmapMode={heatmapMode}
                    onHandleSortTable={onHandleSortTable}
                  />

                  <BodyTableDefault
                    reportNameSystem={reportNameSystem}
                    summary={renewSummary}
                    dataRows={dataRows}
                    headingRows={headingRows}
                    canReadMACost={canReadMACost}
                    headers={headers}
                    subHeadingRows={subHeadingRows}
                    heatmapMode={heatmapMode}
                    dataLink={dataLink}
                    isReportPNL={isReportPNL}
                    benchmark={benchmark}
                    onClickDetailDrilldown={onClickDetailDrilldown}
                    listProductCategoryId={listProductCategoryId}
                    displaySummary={displaySummary}
                    dependdata={dependdata}
                  />
                </table>
              </div>

            </TableScrollable>

            {!isHiddenPagination && (
              <HPagination
                total={data.length}
                onChangePage={onChangePage}
                isLoading={isLoadingTable}
              />
            )}

            <div ref={refDetectScroll}></div>
          </>
        ) : (
          !isLoadingTable &&
          data?.length === 0 && (
            <EmptyTemplate
              text={t("no_data")}
              subText={t("change_fitler_hint")}
            />
          )
        )}
      </div>

      {reportNameSystem === "shipments_carriers_status_by_location" && (
        <div className="hrv-report-row hrv-report-justify-between hrv-report-px-16 hrv-report-pt-8">
          <a
            className="hrv-report-d-flex hrv-report-items-center"
            href="https://support.haravan.com/support/solutions/articles/42000102938-b%C3%A1o-c%C3%A1o-t%E1%BB%95ng-quan-v%E1%BA%ADn-chuy%E1%BB%83n"
            target="_blank"
            rel="noreferrer"
          >
            <span className="hrv-report-mr-4">{svgInfo}</span>

            <p className="hrv-report-mb-0 hrv-report-text-color-blue-5 hrv-report-fs-14">
              {t("Xem cách tối ưu thời gian giao")}
            </p>
          </a>

          <div
            style={{
              minWidth: "347px",
            }}
          >
            <HeatmapDescription pallete={HEATMAP_LEVEL} />
          </div>
        </div>
      )}
    </div>
  );
})



const svgInfo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.1665 5.83366H10.8332V7.50033H9.1665V5.83366ZM9.1665 9.16699H10.8332V14.167H9.1665V9.16699ZM9.99984 1.66699C5.39984 1.66699 1.6665 5.40033 1.6665 10.0003C1.6665 14.6003 5.39984 18.3337 9.99984 18.3337C14.5998 18.3337 18.3332 14.6003 18.3332 10.0003C18.3332 5.40033 14.5998 1.66699 9.99984 1.66699ZM9.99984 16.667C6.32484 16.667 3.33317 13.6753 3.33317 10.0003C3.33317 6.32533 6.32484 3.33366 9.99984 3.33366C13.6748 3.33366 16.6665 6.32533 16.6665 10.0003C16.6665 13.6753 13.6748 16.667 9.99984 16.667Z"
      fill="#2463EB"
    />
  </svg>
);
