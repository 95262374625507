import {
  SET_DATE_RANGE,
  SET_TOP_PRODUCT_MEASURE,
  SET_TOP_POS_MEASURE,
  TOOGLE_CONFIG_MODE,
  TOOGLE_FULL_SCREEN,
  SET_TEMPLATE_DASHBOARD,
  SET_DATE_COMPARE,
  SET_OPTION_COMPARE,
  SET_DEFAULT_TEMPLATE_DASHBOARD,
  SET_DASHBOARD_LIST,
  SET_DASHBOARD_SELECTED,
  SET_DEFAULT_LAYOUTS,
  SET_LABEL_DATE,
  FETCHING_WIDGET_DASHBOARD,
  FETCHING_WIDGET_DASHBOARD_SUCCESS,
  FETCHING_WIDGET_DASHBOARD_ERROR,
  SET_AUTOCOMPLETE_SELECTED,
  FETCHING_DASHBOARD_TAB_ERROR,
  FETCHING_DASHBOARD_TAB_SUCCESS,
  FETCHING_DASHBOARD_TAB,
  SET_OPTION,
  TOGGLE_CONFIG_WIDGET_MODAL,
  SET_DATE_WIDGET,
  TOOGLE_MODAL_CONFIRM,
  IS_LOADING_SAVE,
  IS_LOADING_SAVE_SUCCESS,
  IS_LOADING_SAVE_ERROR,
} from "./constants";

export const setDate = (payload: any) => ({
  type: SET_DATE_RANGE,
  payload,
});
export const setTopProductMeasure = (payload: any) => ({
  type: SET_TOP_PRODUCT_MEASURE,
  payload,
});
export const setTopPosMeasure = (payload: any) => ({
  type: SET_TOP_POS_MEASURE,
  payload,
});

export const toogleConfigMode = (payload: any) => ({
  type: TOOGLE_CONFIG_MODE,
  payload,
});

export const toogleFullScreen = (payload: any) => ({
  type: TOOGLE_FULL_SCREEN,
  payload,
});

export const setTemplateDashboard = (payload: any) => ({
  type: SET_TEMPLATE_DASHBOARD,
  payload,
});

export const setDefaultTemplateDashboard = (payload: any) => ({
  type: SET_DEFAULT_TEMPLATE_DASHBOARD,
  payload,
});

export const setDateCompare = (payload: any) => ({
  type: SET_DATE_COMPARE,
  payload,
});
export const setOption = (payload: any) => ({
  type: SET_OPTION,
  payload,
});

export const setOptionCompare = (payload: any) => ({
  type: SET_OPTION_COMPARE,
  payload,
});

export const setDashboardList = (payload: any) => ({
  type: SET_DASHBOARD_LIST,
  payload,
});

export const setDashboardDefault = (payload: any) => ({
  type: SET_DASHBOARD_SELECTED,
  payload,
});
export const setDefaultLayouts = (payload: any) => ({
  type: SET_DEFAULT_LAYOUTS,
  payload,
});

export const setLabelDate = (payload: any) => ({
  type: SET_LABEL_DATE,
  payload,
});

export const fecthAnalyticWidget = () => ({
  type: FETCHING_WIDGET_DASHBOARD,
});

export const fecthAnalyticWidgetSuccess = () => ({
  type: FETCHING_WIDGET_DASHBOARD_SUCCESS,
});

export const fecthAnalyticWidgetError = () => ({
  type: FETCHING_WIDGET_DASHBOARD_ERROR,
});

export const setAutocompleteSelected = (payload: any) => ({
  type: SET_AUTOCOMPLETE_SELECTED,
  payload,
});
export const fetchingDashboardTab = (payload: any) => ({
  type: FETCHING_DASHBOARD_TAB,
});
export const fetchingDashboardTabSuccess = (payload: any) => ({
  type: FETCHING_DASHBOARD_TAB_SUCCESS,
  payload,
});
export const fetchingDashboardTabError = (payload: any) => ({
  type: FETCHING_DASHBOARD_TAB_ERROR,
  payload,
});

export const toggleConfigWidgetModal = (payload: any) => ({
  type: TOGGLE_CONFIG_WIDGET_MODAL,
  payload,
});

export const setDataWidgetForConfig = (payload: any) => ({
  type: SET_DATE_WIDGET,
  payload
})

export const toggleModalConfirm = (payload: any) => ({
  type: TOOGLE_MODAL_CONFIRM,
  payload,
});

export const setLoadingSave = (payload: any) => ({
  type: IS_LOADING_SAVE,
  payload,
});


export const setLoadingSaveSuccess = (payload: any) => ({
  type: IS_LOADING_SAVE_SUCCESS,
  payload,
});

export const setLoadingSaveError = (payload: any) => ({
  type: IS_LOADING_SAVE_ERROR,
  payload,
});

