import { useTrans } from "locales/hook";
import { Box, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";

import "./styles.scss";

interface IProps {
  name: string;
  createdDate?: string;
  id: any;
  tab: any;
  isSystemReport: boolean;
  reportNameSystem: string;
  isHasChild: boolean;
  childList?: Array<any>;
  onClickDetail: Function;
  icon: any
}

export default function ReportCard(props: IProps) {
  const t = useTrans();

  const {
    name,
    id,
    tab,
    isSystemReport,
    isHasChild,
    childList,
    reportNameSystem,
    onClickDetail,
    icon
  } = props;

  const onClickHandler = () => {
    onClickDetail && onClickDetail(tab, id, reportNameSystem)
  };

  const onClickChildHandler = (tab, id) => {
    onClickDetail && onClickDetail(tab, id, reportNameSystem)
  };

  const nametag = () => {
    return (
      <div className="hrv-report-card__tag">
        <span>{t(tab)}</span>
      </div>
    );
  };

  return (
    <Menu flip={true} placement="bottom-end">
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Box} w="100%"
            className="hrv-report-card hrv-report-cursor"
            onClick={(e) => {
              e.stopPropagation();

              if (!isHasChild) {
                onClickHandler && onClickHandler();

              }
            }}
          >
            <>
              <div className="hrv-report-d-flex hrv-report-items-center">
                <span className="hrv-report-mr-16">
                  {icon}
                </span>

                <div>
                  {nametag()}

                  <p className="hrv-report-card__name hrv-report-card__name_fixed_height">
                    {isSystemReport ? t(name) : name}
                  </p>
                </div>
              </div>
            </>
          </MenuButton>

          <MenuList className="hrv-report-scrollbar_custom" minW={"unset"} w={'164px'}>
            <div
              className="hrv-report-py-4 hrv-report-pr-4 hrv-report-pl-6 hrv-report-scrollbar_custom"
              style={{
                height: "198px",
                maxHeight: "198px",
                overflowY: "auto",
                width: "160px",
              }}
            >
              {childList &&
                childList.map((item, idx) => {
                  return (
                    <MenuItem display="block" key={idx} onClick={() => {
                      onClickChildHandler && onClickChildHandler(item.tab, item.id);
                    }} p={0}>
                      <div className="hrv-report-card__popover-item">
                        <p>{t(item.name)}</p>

                      </div>
                    </MenuItem>

                  );
                })}
            </div>
          </MenuList>
        </>
      )
      }
    </Menu>
  );
}
