import { memo, useEffect, useState } from "react";

import { Responsive, WidthProvider } from "react-grid-layout";

import { deepClone, isEmpty } from "helpers/helpers";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import EmptyDashboardSvg from "assets/images/empty/empty-dashboard.svg";

// Demo
import WidgetClone from "../Widget";
import useWindowDimensions from "hooks/useWindowDimension";
import { useTrans } from "locales/hook";
import { Box } from "@chakra-ui/react";

const ResponsiveGridLayout = WidthProvider(Responsive);

function DashboardWidgetList(props) {
  const {
    isConfigMode,
    templateDashboard,
    onHandleToggleWidget,
    onHandleDragStop,
    startdate,
    enddate,
    compareDateEnd,
    compareDateStart,
    optionCompare,
    isFullScreen,
    autoCompleteSelected,
    autoComplete,
    onHandleOpenOptionalConfig,
    dashboardId,
    addActive
  } = props;

  const [renderTemplate, setRenderTemplate] = useState<any>([]);
  const [mobile, setMobile] = useState([]);
  const [currentIdResizing, setCurrentIdResizing] = useState(0);
  const [currentHeightResizing, setCurrentHeightResizing] = useState(0);
  const [currentIdDrag, setCurrentIdDrag] = useState(1);

  const [layoutsLg, setLayoutsLg] = useState([]);
  let { width } = useWindowDimensions();
  const t = useTrans();

  const currentCol = 8;
  const paddingX = 64 + 64 + 12;
  const widthSideBar = 259;
  const hardHeightMobile = 126;

  const spacious = isFullScreen ? widthSideBar + paddingX : paddingX;

  let final: any = [];

  Object.keys(templateDashboard).forEach((key) => {
    const getReportDisplay = templateDashboard[key].filter((t) => t.isDisplay);

    if (getReportDisplay.length) {
      final.push(...getReportDisplay);
    }
  });

  // const [rect, refSize] = useSizeForGrid();

  // let width = rect ? rect.width : 1245;

  if (!isFullScreen && width > 1245) {
    width = 1245;
  }

  const maxHeightCol = (width - spacious) / currentCol;

  useEffect(() => {
    if (!isEmpty(templateDashboard)) {
      let result: any = [];
      let resultLayouts: any = [];

      const cloneObject = deepClone(templateDashboard);

      Object.keys(cloneObject).forEach((key) => {
        const getReportDisplay = cloneObject[key].filter((t) => t.isDisplay);

        if (getReportDisplay.length) {
          result.push(...getReportDisplay);
        }
      });

      const demo = result.sort((a, b) =>
        a.template.y > b.template.y ? 1 : -1,
      );

      if (width < 768) {
        return setMobile(demo);
      } else {
        resultLayouts = demo.map((y: any) => {
          return {
            ...y.template,
            i: y.widgetId,
          };
        });

        setLayoutsLg(resultLayouts);
        setRenderTemplate(result.flat());
      }
    }
  }, [templateDashboard, width]);

  if (!final.length) {
    return (
      <div className="hrv-report-flex-col hrv-report-mt-71 hrv-report-d-flex hrv-report-justify-center hrv-report-items-center">

        <EmptyDashboardSvg />

        <Box mt={20} textStyle="h4" className="hrv-report-text-color-black-6">
          {t("Bắt đầu tạo bảng báo cáo")}
        </Box>

        <p className="hrv-report-text-center hrv-report-mt-4 hrv-report-mb-0 hrv-report-text-color-black-6 hrv-report-fs-14">

          {t("Hãy thêm biểu đồ bạn cần từ \"Thư viện báo cáo\"")}

        </p>
      </div>
    );
  }

  return (
    <>
      {width < 758 ? (
        <>
          {mobile.map((report: any) => {
            return (
              <div
                style={{
                  width: "100%",
                  height:
                    report.chartType !== "number"
                      ? 3 * hardHeightMobile
                      : hardHeightMobile,
                }}
                className="hrv-report-mt-16"
              >
                <WidgetClone
                  {...report}
                  reportId={report.reportScreenId}
                  isConfigMode={isConfigMode}
                  isFullScreen={isFullScreen}
                  onHandleToggleWidget={onHandleToggleWidget}
                  startdate={startdate}
                  enddate={enddate}
                  compareDateEnd={compareDateEnd}
                  compareDateStart={compareDateStart}
                  optionCompare={optionCompare}
                  currentIdResizing={currentIdResizing}
                  currentHeightResizing={currentHeightResizing}
                  autoCompleteSelected={autoCompleteSelected}
                  autoComplete={autoComplete}
                  widget={report}
                  currentIdDrag={currentIdDrag}
                />
              </div>
            );
          })}
        </>
      ) : (
        <div className="hrv-report-wrapper-grid-drag-layout">
          {!isEmpty(templateDashboard) && (
            <ResponsiveGridLayout
              margin={[12, 12]}
              className="layout"
              layouts={{
                lg: layoutsLg,
              }}
              cols={{
                lg: currentCol,
              }}
              breakpoints={{
                lg: 1200,
              }}
              rowHeight={maxHeightCol}
              isDraggable={isConfigMode}
              isResizable={isConfigMode}
              onDragStop={(layout, item) => {
                setCurrentIdDrag(0);
                onHandleDragStop && onHandleDragStop(layout);
              }}
              onDrag={(layout, item) => {
                if (item.i !== currentIdDrag) {
                  setCurrentIdDrag(item.i);
                }
              }}
              onLayoutChange={(layout) => {
                onHandleDragStop && onHandleDragStop(layout);
              }}
              onResize={(layout, oldItem, newItem, placeholder, e, element) => {
                setCurrentIdResizing(newItem.i);
                setCurrentIdDrag(newItem.i);

                const ele: any = document.getElementById(
                  `hrv-report-widget-id-${newItem.i}`,
                );

                let getAttributeHeight: number = 0;

                if (ele !== null) {
                  getAttributeHeight = ele
                    .getAttribute("style")
                    .match(/height:\s*([\d.]+px)/);
                }
                setCurrentHeightResizing(getAttributeHeight);
              }}
              onResizeStop={(layout, item, newItem) => {
                setCurrentIdResizing(0);
                setCurrentIdDrag(0);

                const ele: any = document.getElementById(
                  `hrv-report-widget-id-${newItem.i}`,
                );

                let getAttributeHeight: number = 0;

                if (ele !== null) {
                  getAttributeHeight = ele
                    .getAttribute("style")
                    .match(/height:\s*([\d.]+px)/);
                }

                setCurrentHeightResizing(getAttributeHeight);
              }}
              draggableCancel=".react-disabled-draggable"
            >
              {renderTemplate.map((report: any) => {
                return (
                  <div
                    key={report.widgetId}
                    id={`hrv-report-widget-id-${report.widgetId}`}
                  >
                    <WidgetClone
                      {...report}
                      reportId={report.reportScreenId}
                      isConfigMode={isConfigMode}
                      isFullScreen={isFullScreen}
                      onHandleToggleWidget={onHandleToggleWidget}
                      startdate={startdate}
                      enddate={enddate}
                      compareDateEnd={compareDateEnd}
                      compareDateStart={compareDateStart}
                      optionCompare={optionCompare}
                      currentIdResizing={currentIdResizing}
                      currentHeightResizing={currentHeightResizing}
                      autoCompleteSelected={autoCompleteSelected}
                      autoComplete={autoComplete}
                      onHandleOpenOptionalConfig={onHandleOpenOptionalConfig}
                      widget={report}
                      currentIdDrag={currentIdDrag}
                      dashboardId={dashboardId}
                      addActive={addActive}
                    />
                  </div>
                );
              })}
            </ResponsiveGridLayout>
          )}
        </div>
      )}
    </>
  );
}

export default memo(DashboardWidgetList);
