const scope = "dashboardv2Actions/";
export const SET_DATE_RANGE = scope + "SET_DATE_RANGE";
export const SET_LABEL_DATE = scope + "SET_LABEL_DATE";
export const SET_TOP_PRODUCT_MEASURE = scope + "SET_TOP_PRODUCT_MEASURE";
export const SET_TOP_POS_MEASURE = scope + "SET_TOP_POS_MEASURE";

export const TOOGLE_CONFIG_MODE = scope + "TOOGLE_CONFIG_MODE";
export const TOOGLE_FULL_SCREEN = scope + "TOOGLE_FULL_SCREEN";

export const SET_TEMPLATE_DASHBOARD = scope + "SET_TEMPLATE_DASHBOARD";
export const SET_DEFAULT_TEMPLATE_DASHBOARD =
  scope + "SET_DEFAULT_TEMPLATE_DASHBOARD";

export const SET_DASHBOARD_LIST = scope + "SET_DASHBOARD_LIST";
export const SET_DASHBOARD_SELECTED = scope + "SET_DASHBOARD_SELECTED";

export const SET_DATE_COMPARE = scope + "SET_DATE_COMPARE";
export const SET_OPTION_COMPARE = scope + "SET_OPTION_COMPARE";
export const SET_OPTION = scope + "SET_OPTION";

export const TOGGLE_CONFIG_WIDGET_MODAL = scope + "TOGGLE_CONFIG_WIDGET_MODAL";
export const SET_DATE_WIDGET = scope + "SET_DATE_WIDGET";

export const SET_DEFAULT_LAYOUTS = scope + "SET_DEFAULT_LAYOUTS";
export const FETCHING_WIDGET_DASHBOARD = scope + "FETCHING_WIDGET_DASHBOARD";
export const FETCHING_WIDGET_DASHBOARD_SUCCESS =
  scope + "FETCHING_WIDGET_DASHBOARD_SUCCESS";
export const FETCHING_WIDGET_DASHBOARD_ERROR =
  scope + "FETCHING_WIDGET_DASHBOARD_ERROR";

export const FETCHING_AUTOCOMPLETE = scope + "FETCHING_AUTOCOMPLETE";
export const FETCHING_AUTOCOMPLETE_SUCCESS =
  scope + "FETCHING_AUTOCOMPLETE_SUCCESS";
export const FETCHING_AUTOCOMPLETE_ERROR =
  scope + "FETCHING_AUTOCOMPLETE_ERROR";

export const SET_AUTOCOMPLETE_SELECTED = scope + "SET_AUTOCOMPLETE_SELECTED";
export const FETCHING_DASHBOARD_TAB = scope + "FETCHING_DASHBOARD_TAB";
export const FETCHING_DASHBOARD_TAB_SUCCESS =
  scope + "FETCHING_DASHBOARD_TAB_SUCCESS";
export const FETCHING_DASHBOARD_TAB_ERROR =
  scope + "FETCHING_DASHBOARD_TAB_ERROR";

export const IS_LOADING_SAVE = "IS_LOADING_SAVE";
export const IS_LOADING_SAVE_SUCCESS = "IS_LOADING_SAVE_SUCCESS";
export const IS_LOADING_SAVE_ERROR = "IS_LOADING_SAVE_ERROR";

// Toggle Modal
export const TOOGLE_MODAL_CONFIRM = scope + "TOOGLE_MODAL_CONFIRM";

export const category_chart = {
  NUMBER: "number",
  LINE: "line",
  TABLE: "table",
  COLUMN: "column",
  HORIZONTALBAR: "horizontalbar",
  STACKEDCOLUMN: "stackedhorizalbar",
  STACKEDBAR: "stackedbar",
  DOUGHNUT: "doughnut",
  CHANNEL: "channel",
  PRODUCT: "product",
  STAFF: "staff",
  LOCATION: "location",
  TREEMAP: "treemap",
  GROUPEDHORIZONTALCOLUMN: "groupedhorizontalcolumn",
  GROUPEDCOLUMN: "groupedcolumn",
  HORIZONTALCOLUMN: "horizontalcolumn",
  BAR: "bar"
};