import { useTrans } from "locales/hook";
import { Fragment, memo } from "react";

import EmptyTemplate from "components/EmptyTemplate";
import HLimitLongString from "components/LimitLongString";
import Money from "components/Money";
import MoneyWithLocale from "components/Money/locale";
import { ProductImageDisplay } from "components/ProductImage/index";
import StatusPeriod from "components/StatusPeriod";

import Avatar from "components/Avatar";
import {
  GetComparison,
  formatNumber,
  linkTypeInTable,
  queryString,
  sprintf,
} from "helpers/helpers";
import { useProductFeatureImagesByIds } from "models/swr/product";

function GridSystemLayout(props) {
  const { type, dataCurrent, dataPrevious, isComparisonDate, option, configHeight } = props;

  const shop: any = queryString.shop;

  const t = useTrans();

  const { data: productImages } = useProductFeatureImagesByIds(type, dataCurrent);

  const XMLRenderContent = () => {
    switch (type) {
      case "channel":
        return (
          <table className="hrv-report-w-100 hrv-report-table-list">
            <tbody>
              {dataCurrent?.data?.map((item, index) => {
                const findPositionOrder = dataCurrent.headerreport.findIndex(
                  (y) => y.fieldName === "OrderCountSpecial",
                );

                const findPositionNetSale = dataCurrent.headerreport.findIndex(
                  (y) => y.fieldName === "NetSaleAmount",
                );

                let comparison = {
                  trend: true,
                  infinity: true,
                  value: 0,
                };



                if (
                  isComparisonDate &&
                  dataPrevious?.data?.length &&
                  dataPrevious
                ) {

                  let pIndex = dataPrevious?.data?.findIndex(
                    (y) => y[0] === item[0],
                  );

                  if (pIndex !== -1) {
                    comparison = GetComparison(
                      item[findPositionNetSale],
                      dataPrevious?.data[pIndex][findPositionNetSale],
                    );
                  }
                }

                return (
                  <tr key={index}>
                    <td className="hrv-report-text-left">
                      <HLimitLongString
                        customClass="hrv-report-my-4 hrv-report-fs-14 hrv-report-color-title hrv-report-fw-500"
                        text={item[0]}
                        isLink={false}
                        lengthText={30}
                      />

                      <p className="hrv-report-my-0 hrv-report-text-color-gray-9">
                        {sprintf(
                          t("number_orders"),
                          formatNumber(item[findPositionOrder]),
                        )}
                      </p>
                    </td>

                    <td className="hrv-report-text-right">
                      <MoneyWithLocale value={item[findPositionNetSale]} />
                      {isComparisonDate && (
                        <StatusPeriod
                          value={formatNumber(comparison?.value)}
                          trend={comparison?.trend}
                          infinity={comparison?.infinity}
                          className="hrv-report-justify-end"
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );

      case "product":
        return (
          <table className="hrv-report-w-100 hrv-report-table-list">
            <tbody>
              {dataCurrent?.data.map((item, index) => {
                let pIndex = -1;

                let comparison = {
                  trend: true,
                  infinity: true,
                  value: 0,
                };
                var productImageIndex = productImages?.data.findIndex((p) => p.productId === dataCurrent?.datalink[index][0]);
                var productImageUrl = '';
                if (productImageIndex !== -1) {
                  productImageUrl = productImages?.data[productImageIndex].url;
                }

                const findPositionOption = dataCurrent?.headerreport?.findIndex(
                  (y) => y.fieldName === option,
                );

                if (
                  isComparisonDate &&
                  dataPrevious &&
                  dataPrevious?.data?.length
                ) {
                  pIndex = dataPrevious?.data?.findIndex(
                    (y) => y[0] === item[0],
                  );

                  if (pIndex !== -1) {
                    comparison = GetComparison(
                      item[1],
                      dataPrevious?.data[pIndex][findPositionOption],
                    );
                  }
                }

                let productName = item[0];
                let isLink = true;
                let link = "";
                let customClass =
                  "hrv-report-my-4 hrv-report-fs-14 hrv-report-fw-500";

                if (dataCurrent?.datalink[index][0] === 0) {
                  productName = "Sản phẩm tùy chọn";
                  isLink = false;
                } else {
                  link = `https://${shop}/admin/${linkTypeInTable(
                    "ProductId",
                  )}/${dataCurrent?.datalink[index][0]}`;
                  customClass +=
                    " hrv-report-text-color-blue-2 hrv-report-cursor-pointer";
                }

                if (item[1] === 0 && comparison.infinity) {
                  return <Fragment key={index}></Fragment>;
                }

                return (
                  <tr
                    key={index}
                    className="hrv-report-top-product-dashboard-item"
                  >
                    <td className="hrv-report-text-left hrv-report-xs-px-0">
                      <div className="hrv-report-d-flex hrv-report-items-center">
                        <div className="hrv-report-pr-16">
                          <ProductImageDisplay url={productImageUrl} />
                        </div>
                        <HLimitLongString
                          customClass={customClass}
                          text={productName}
                          link={link}
                          isLink={isLink}
                          lengthText={30}
                        />
                      </div>
                    </td>
                    <td className="hrv-report-text-right hrv-report-xs-px-0">
                      {option === "NetSaleAmount" ? (
                        <MoneyWithLocale value={item[findPositionOption]} />
                      ) : (
                        <Money
                          value={item[findPositionOption]}
                          suffix={t("unit_product")}
                          suffixClass={""}
                        />
                      )}

                      <StatusPeriod
                        value={formatNumber(comparison.value)}
                        trend={comparison.trend}
                        infinity={comparison.infinity}
                        className="hrv-report-justify-end"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );

      case "location":
        return (
          <table className="hrv-report-w-100 hrv-report-table-list">
            <tbody>
              {dataCurrent?.data.map((item, index) => {
                let pIndex = -1;

                let comparison = {
                  trend: true,
                  infinity: true,
                  value: 0,
                };

                const findPositionOrder = dataCurrent.headerreport.findIndex(
                  (y) => y.fieldName === "OrderCountSpecial",
                );

                const findPositionOptional = dataCurrent.headerreport.findIndex(
                  (y) => y.fieldName === option,
                );

                if (isComparisonDate) {
                  pIndex = dataPrevious?.data?.findIndex(
                    (y) => y[0] === item[0],
                  );

                  if (pIndex !== -1) {
                    comparison = GetComparison(
                      item[findPositionOptional],
                      dataPrevious?.data[pIndex][findPositionOptional],
                    );
                  }
                }

                return (
                  <tr key={index}>
                    <td className="hrv-report-text-left hrv-report-xs-px-0">
                      <HLimitLongString
                        customClass="hrv-report-my-4 hrv-report-fs-14 hrv-report-fw-500 hrv-report-color-title"
                        text={item[0]}
                        isLink={false}
                        lengthText={30}
                      />
                      <p className="hrv-report-my-0 hrv-report-text-color-gray-9">
                        {sprintf(
                          t("number_orders"),
                          formatNumber(item[findPositionOrder]),
                        )}
                      </p>
                    </td>

                    <td className="hrv-report-text-right hrv-report-xs-px-0">
                      <MoneyWithLocale value={item[findPositionOptional]} />

                      <StatusPeriod
                        value={formatNumber(comparison.value)}
                        trend={comparison.trend}
                        infinity={comparison.infinity}
                        className="hrv-report-justify-end"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );

      case "staff":
        return (
          <table className="hrv-report-w-100 hrv-report-table-list">
            <tbody>
              {dataCurrent?.data.map((item, index) => {
                const findPositionOrder = dataCurrent.headerreport.findIndex(
                  (y) => y.fieldName === "OrderCountSpecial",
                );
                const findPositionNetSale = dataCurrent.headerreport.findIndex(
                  (y) => y.fieldName === "NetSaleAmount",
                );

                if (dataCurrent?.datalink[index][0]) {
                  let pIndex = -1;
                  let trend = true;
                  let valueTrend = 0;
                  let infinity = false;

                  if (
                    isComparisonDate &&
                    dataPrevious &&
                    dataPrevious?.data?.length
                  ) {
                    pIndex = dataPrevious?.data?.findIndex((y) => {
                      return y[0].trim() === item[0].trim();
                    });

                    if (pIndex !== -1) {
                      const periodData = dataPrevious?.data[pIndex];

                      trend =
                        item[findPositionNetSale] -
                          periodData[findPositionNetSale] <
                          0
                          ? false
                          : true;

                      infinity = true;

                      if (periodData[findPositionNetSale] > 0) {
                        infinity = false;
                        valueTrend =
                          (Math.abs(
                            item[findPositionNetSale] -
                            periodData[findPositionNetSale],
                          ) /
                            periodData[findPositionNetSale]) *
                          100;
                      }
                    }
                  }

                  return (
                    <tr key={index}>
                      <td className="hrv-report-text-left hrv-report-xs-px-0">
                        <div className="hrv-report-d-flex">
                          <div className="hrv-report-pr-16">
                            <Avatar name={item[0]} isSystem={false} size={32} />
                          </div>
                          <div>
                            <HLimitLongString
                              customClass="hrv-report-my-0 hrv-report-text-color-blue-2 hrv-report-cursor-pointer"
                              text={item[0]}
                              link={`https://${shop}/admin/${linkTypeInTable(
                                "PaidUserId",
                              )}/${dataCurrent?.datalink[index][0]}`}
                              isLink={item[0] !== "Hệ thống"}
                              lengthText={30}
                            />
                            <p className="hrv-report-my-0 hrv-report-text-color-gray-9">
                              {sprintf(
                                t("sell_number_orders"),
                                item[findPositionOrder],
                              )}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="hrv-report-text-right hrv-report-xs-px-0">
                        <MoneyWithLocale value={item[findPositionNetSale]} />
                        {valueTrend !== 0 ? (
                          <StatusPeriod
                            value={formatNumber(valueTrend)}
                            trend={trend}
                            infinity={infinity}
                            className="hrv-report-justify-end"
                          />
                        ) : (
                          <div>--</div>
                        )}
                      </td>
                    </tr>
                  );
                }

                return <Fragment key={index}></Fragment>;
              })}
            </tbody>
          </table>
        );

      default:
        return <p>Not Right Type</p>;
    }
  };

  const XMLEmptySpace = () => {
    return <div
      className="hrv-report-d-flex hrv-report-justify-center hrv-report-items-center"
      style={{
        height: configHeight,
      }}
    >
      <EmptyTemplate text={t("no_data")} />
    </div>
  };

  return (
    <div
      className="hrv-report-d-flex hrv-report-justify-center hrv-report-items-center"
      style={{
        height: '100%',
      }}
    >
      {
        !dataCurrent?.data?.length ||
          (type === "staff" && dataCurrent?.data?.length === 1 && !dataCurrent?.datalink[0][0]) ?
          XMLEmptySpace() :
          XMLRenderContent()
      }
    </div>
  );
}

export default memo(GridSystemLayout);
