import dayjs from "components/Dayjs"
import {
  SET_DATE_RANGE,
  SET_TOP_PRODUCT_MEASURE,
  SET_TOP_POS_MEASURE,
  TOOGLE_CONFIG_MODE,
  TOOGLE_FULL_SCREEN,
  SET_TEMPLATE_DASHBOARD,
  SET_DEFAULT_TEMPLATE_DASHBOARD,
  SET_DATE_COMPARE,
  SET_OPTION_COMPARE,
  SET_DASHBOARD_LIST,
  SET_DASHBOARD_SELECTED,
  SET_DEFAULT_LAYOUTS,
  SET_LABEL_DATE,
  FETCHING_WIDGET_DASHBOARD,
  FETCHING_WIDGET_DASHBOARD_SUCCESS,
  FETCHING_WIDGET_DASHBOARD_ERROR,
  SET_AUTOCOMPLETE_SELECTED,
  FETCHING_DASHBOARD_TAB,
  FETCHING_DASHBOARD_TAB_SUCCESS,
  FETCHING_DASHBOARD_TAB_ERROR,
  TOGGLE_CONFIG_WIDGET_MODAL,
  SET_DATE_WIDGET,
  TOOGLE_MODAL_CONFIRM,
  IS_LOADING_SAVE,
  IS_LOADING_SAVE_SUCCESS,
  IS_LOADING_SAVE_ERROR,
} from "./constants";

// import { queryString } from "helpers/helpers";

export const initialState = {
  top_product_measure: "NetSaleAmount",
  top_pos_measure: "NetSaleAmount",

  inConfigMode: false,
  isFullScreen: false,

  templateDashboard: {},
  defaultTemplateDashboard: {},

  dashboardList: [],
  dashboardSelected: {},

  isLoadingWidgetDashboard: true,
  isLoadingTabWidget: true,

  // * Ngày lọc
  startdate: dayjs(),
  enddate: dayjs(),
  optionDate: "Hôm nay",

  // * Ngày so sánh
  compareDateEnd: dayjs(),
  compareDateStart: dayjs(),
  optionCompare: "Kỳ trước",

  // * default layout
  defaultLayout: {},

  autoCompleteSelected: {
    SourceName: [],
    LocationId: [],
  },

  isOpenConfigWidgetModal: false,
  widgetForConfig: {},
  isOpenModalConfirm: false,
  messageHeaderContent: "",
  messageBodyContent: "",

  isLoadingSave: false
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_DATE_RANGE:
      return {
        ...state,
        startdate: action.payload.startDate,
        enddate: action.payload.endDate,
      };

    case SET_LABEL_DATE:
      return {
        ...state,
        optionDate: action.payload,
      };

    case SET_TOP_PRODUCT_MEASURE:
      return { ...state, top_product_measure: action.payload };

    case SET_TOP_POS_MEASURE:
      return { ...state, top_pos_measure: action.payload };

    case TOOGLE_CONFIG_MODE:
      return { ...state, inConfigMode: action.payload };

    case TOOGLE_FULL_SCREEN:
      return { ...state, isFullScreen: action.payload };

    case SET_TEMPLATE_DASHBOARD:
      return { ...state, templateDashboard: action.payload };

    case SET_DEFAULT_TEMPLATE_DASHBOARD:
      return { ...state, defaultTemplateDashboard: action.payload };

    case SET_DATE_COMPARE:
      return {
        ...state,
        compareDateStart: action.payload.startDate,
        compareDateEnd: action.payload.endDate,
      };

    case SET_OPTION_COMPARE:
      return {
        ...state,
        optionCompare: action.payload,
      };

    case SET_DASHBOARD_LIST:
      return {
        ...state,
        dashboardList: action.payload,
      };

    case SET_DASHBOARD_SELECTED:
      return {
        ...state,
        defaultTemplateDashboard: action.payload,
      };

    case SET_DEFAULT_LAYOUTS:
      return {
        ...state,
        defaultLayout: action.payload,
      };

    case FETCHING_WIDGET_DASHBOARD:
      return {
        ...state,
        isLoadingWidgetDashboard: true,
      };

    case FETCHING_WIDGET_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoadingWidgetDashboard: false,
      };

    case FETCHING_WIDGET_DASHBOARD_ERROR:
      return {
        ...state,
        isLoadingWidgetDashboard: false,
      };

    case SET_AUTOCOMPLETE_SELECTED:
      return {
        ...state,
        autoCompleteSelected: action.payload,
      };

    case FETCHING_DASHBOARD_TAB:
      return {
        ...state,
        isLoadingTabWidget: true,
      };

    case FETCHING_DASHBOARD_TAB_SUCCESS:
      return {
        ...state,
        isLoadingTabWidget: false,
      };

    case FETCHING_DASHBOARD_TAB_ERROR:
      return {
        ...state,
        isLoadingTabWidget: false,
      };

    case TOGGLE_CONFIG_WIDGET_MODAL:
      return {
        ...state,
        isOpenConfigWidgetModal: action.payload,
      };

    case SET_DATE_WIDGET:
      return {
        ...state,
        widgetForConfig: action.payload,
      }

    case TOOGLE_MODAL_CONFIRM:
      return {
        ...state,
        isOpenModalConfirm: action.payload.status,
        messageHeaderContent: action.payload.header,
        messageBodyContent: action.payload.body,
      };

    case IS_LOADING_SAVE:
      return {
        ...state,
        isLoadingSave: true,
      };

    case IS_LOADING_SAVE_SUCCESS:
      return {
        ...state,
        isLoadingSave: false,
      };

    case IS_LOADING_SAVE_ERROR:
      return {
        ...state,
        isLoadingSave: false,
      };


    default:
      throw new Error("Action invalid");
  }
};

export default reducer;
