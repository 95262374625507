import { useAutoCompleteV2 } from "components/Filter/swr/useAutocomplete";
import { useTrans } from "locales/hook";
import { useStore } from "pages/ReportStandard/context/store";
import { useEffect, useRef, useState } from "react";

// ! Components
import ChipGroup from "components/ChipGroup";
import ColumnShowV2 from "components/ColumnShow";
import EmptyTemplate from "components/EmptyTemplate";
import FilterV2 from "components/Filter";
import { SkeletonTable } from "components/TableSkeleton";

// ! Table apply for default
import { TableByDefault } from "./TableByDefault";

// ! Table apple for discount
import DrillDown from "components/DrillDown";
import TableByDiscount from "./TableByDiscount";

import { isEmpty, removeInvalidFilterFromFilterTag } from "helpers/helpers";

import { DrilldownAction, drilldownHandler } from "stategy/drilldown";

import "./styles.scss";

interface IProps {
  reportNameSystem: string;
  canReadMACost: boolean;
  headers: any;
  data: any;
  dataLink?: any;
  summary: Array<any>;
  groupBy: any;
  groupAreas: any;
  onChangeCheckbox?: any;
  measure?: any;
  handleDrag?: any;
  groupBySelected?: any;
  measureSelected?: any;
  handleChangeColumnShowModal: any;
  handleApplyDisplayColumn?: any;
  onHandleSortTable?: any;
  sortValue?: any;
  optionDimension?: any;
  filterTag?: any;
  onHandleClose?: any;
  onClickExport?: any;
  onChangePage?: any;
  isHiddenPagination?: boolean;
  isLoadingTable?: boolean;
  isAccessReportDiscount?: boolean;
  optionMeasure?: any;
  onChangeYAxis?: any;
  discount?: any;
  benchmark?: any;
  optionsHeatMap?: any;
  onChangeHeatmaps?: any;
  reportName?: string;
  dateStart: any;
  dateEnd: any;
  reportId?: string | number;
  submitFilterV2: any;
  dataExport: any;
  onHandleTriggerGroupByAndFilter: any;
  dependdata?: any;
  filterSelected: Array<any>;
  onHandleCloseFilterByDimension: Function
}

const REPORT_SUPPORT_DRILLDOWN = [
  "stock_invreceive_all",
  "stock_transactionhistory_alltime",
  "stock_transactionhistory_adv",
  'stock_onhand'
]

export default function TableReport(props: IProps) {
  const { state, dispatch, actions } = useStore();

  const { optionCompare, isVisibleDrilldown, drilldownState } = state;

  const {
    reportNameSystem,
    canReadMACost,
    headers,
    data,
    dataLink,
    summary,
    groupBy,
    groupAreas,
    measure,
    groupBySelected,
    measureSelected,
    handleChangeColumnShowModal,
    onHandleSortTable,
    sortValue,
    optionDimension,
    filterTag,
    onChangePage,
    isHiddenPagination,
    isLoadingTable,
    isAccessReportDiscount,
    optionMeasure,
    onChangeYAxis,
    discount,
    benchmark,
    optionsHeatMap,
    onChangeHeatmaps,
    dateStart,
    dateEnd,
    reportId,
    submitFilterV2,
    onHandleTriggerGroupByAndFilter,
    dependdata,
    filterSelected,
    onHandleCloseFilterByDimension
  } = props;

  const ref = useRef<any>(null);
  const [previousState, setPreviousState]: any = useState()
  const t = useTrans();

  // * Tạm thời gọi autoComplete cho ngành hàng trước 
  const { data: listProductCategoryId } = useAutoCompleteV2({
    id: reportId,
    body: {
      enddate: dateEnd,
      startdate: dateStart,
      dimension: 'ProductCategoryId',
      keyword: "",
    },
    preventFetch: !REPORT_SUPPORT_DRILLDOWN.includes(reportNameSystem)
  });

  const { data: listSupplierId } = useAutoCompleteV2({
    id: reportId,
    body: {
      enddate: dateEnd,
      startdate: dateStart,
      dimension: 'SupplierId',
      keyword: "",
    },
    preventFetch: !["stock_invreceive_by_suppliers"].includes(reportNameSystem)
  });

  // * If FilterTag not includes Drilldown in filter (close drilldown)
  useEffect(() => {
    if (!isVisibleDrilldown) {
      return
    } else {
      const fieldNames = {
        stock_transactionhistory_alltime: ['ProductCategoryId', 'ProductByCategoryId'],
        stock_transactionhistory_adv: ['ProductCategoryId', 'ProductByCategoryId'],
        stock_onhand: ['ProductCategoryId', 'ProductByCategoryId'],
        stock_invreceive_all: ['ProductCategoryId', 'ProductByCategoryId'],
        stock_invreceive_by_suppliers: ['SupplierId'],
      };

      if (fieldNames.hasOwnProperty(reportNameSystem)) {
        let isExistFilter = true;
        let isExistQuery = true;

        // * Group by hỗ trợ nếu không thuộc trong list thì sẽ auto đóng
        const supportGroupBy = {
          stock_transactionhistory_alltime: ['ProductCategoryId'],
          stock_transactionhistory_adv: ['ProductCategoryId'],
          stock_onhand: ['ProductCategoryId'],
          stock_invreceive_all: ['ProductCategoryId'],
          stock_invreceive_by_suppliers: ['ProductId', 'VariantId'],
        };

        const getFieldGroup = groupBySelected.map((y) => y.groupPropertyField)


        const compared2GroupBy = JSON.stringify(supportGroupBy[reportNameSystem]) !== JSON.stringify(getFieldGroup)

        if (reportNameSystem !== "stock_invreceive_by_suppliers") {
          isExistFilter = filterTag.findIndex(y => fieldNames[reportNameSystem].includes(y.dimension)) !== -1;
          isExistQuery = Boolean(filterTag.filter(y => fieldNames[reportNameSystem].includes(y.dimension))[0]?.query?.length);
        }

        if (!isExistFilter || !isExistQuery || compared2GroupBy) {
          dispatch(actions.setDrilldownState({}));

          dispatch(actions.toggleVisibleDrilldown(false));
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTag, groupBySelected, isVisibleDrilldown]);

  const handleChangeYAxis = (value) => {
    onChangeYAxis && onChangeYAxis(value);
  };

  const displayFilter = optionDimension.filter(o => o.isDisplay);

  let isShowHeatMap = false;


  const handleClickOpenDrilldown = (payload) => {
    dispatch(actions.toggleVisibleDrilldown(true));
    setPreviousState(filterSelected)

    const newFilterTag: any = REPORT_SUPPORT_DRILLDOWN.includes(reportNameSystem) ? filterTag : filterSelected
    const { name, format, isLastChild } = payload;

    const list = {
      SupplierId: listSupplierId,
      ProductCategoryId: listProductCategoryId,
    };

    const { groupByNew, filters, currentItem, rename } = drilldownHandler(name, DrilldownAction.OPEN, {
      groupBy,
      groupBySelected,
      list: list[name],
      t,
      filterTag: newFilterTag,
      ...payload
    });

    dispatch(actions.setDrilldownState({
      name: rename,
      value: currentItem.name,
      format,
      isLastChild
    }));

    onHandleTriggerGroupByAndFilter && onHandleTriggerGroupByAndFilter(
      {
        filters,
        groupBy: groupByNew,
        measures: measureSelected
      }
    );
  }

  const handleClickBackDrilldown = () => {
    const { name, isLastChild } = drilldownState;
    const drilldownName: any = REPORT_SUPPORT_DRILLDOWN.includes(reportNameSystem) ? "ProductCategoryId" : "SupplierId";
    const newFilterTag: any = REPORT_SUPPORT_DRILLDOWN.includes(reportNameSystem) ? filterTag : previousState;

    const list = {
      ProductCategoryId: listProductCategoryId,
    };

    const { parentId, filters, groupByNew, currentItem, rename } = drilldownHandler(
      drilldownName,
      DrilldownAction.BACK,
      {
        list: list[drilldownName],
        groupBySelected,
        name,
        filterTag: newFilterTag,
        groupBy,
        isLastChild
      }
    );

    if (parentId === 0 || (parentId === 0 && name !== 'ProductByCategoryId')) {
      dispatch(actions.toggleVisibleDrilldown(false))
      dispatch(actions.setDrilldownState({}))
    } else {
      dispatch(actions.setDrilldownState({
        name: rename,
        value: currentItem[0].displayText,
        format: 'treeview',
        isLastChild: drilldownState.isLastChild
      }))
    }

    // & Click close (Set default groupby is ProductCategoryId and remove all filter related to ProductCategoryId)
    onHandleTriggerGroupByAndFilter && onHandleTriggerGroupByAndFilter(
      {
        filters,
        groupBy: groupByNew,
        measures: measureSelected
      }
    );
  }

  const handleClickCloseDrilldown = () => {

    dispatch(actions.toggleVisibleDrilldown(false))
  }

  return (
    <div>
      <DrillDown
        defaultVisible={isVisibleDrilldown}
        defaultValue={drilldownState}
        onHandleSave={handleClickCloseDrilldown}
        onHandleBack={handleClickBackDrilldown}
        isShowCloseBtn={drilldownState.name !== "ProductByCategoryId"}
      />

      <div
        className={`hrv-report-whitebox-no-bs hrv-report-px-0 hrv-report-mb-16 ${isVisibleDrilldown ? "" : "hrv-report-mt-16"} ${isLoadingTable ? "hrv-report-table-container__loading" : ""
          }`}
        id="statistical_tables"
        style={{
          overflow: "hidden",
          borderTopRightRadius: isVisibleDrilldown ? 0 : 16,
          borderTopLeftRadius: isVisibleDrilldown ? 0 : 16
        }}
      >

        {isLoadingTable && (
          <div className="hrv-report-table-container__loading-masked"></div>
        )}

        <div className="hrv-report-mb-16 hrv-report-px-16 hrv-report-d-flex hrv-report-justify-between">
          {displayFilter.length === 0 ? <div></div> : (
            <FilterV2
              optionsDimension={optionDimension}
              filterDefault={removeInvalidFilterFromFilterTag(filterTag)}
              dateStart={dateStart}
              dateEnd={dateEnd}
              reportId={reportId}
              submitFilterV2={submitFilterV2}
              disableFilterField={isVisibleDrilldown ? ["ProductCategoryId", "ProductByCategoryId"] : []}
              reportNameSystem={reportNameSystem}
            />
          )}

          <ColumnShowV2
            groupAreas={groupAreas}
            groupBy={groupBy}
            measure={measure}
            measureSelected={measureSelected}
            groupBySelected={groupBySelected}
            handleChangeColumnShowModal={handleChangeColumnShowModal}
            reportNameSystem={reportNameSystem}
            optionYAxis={optionMeasure}
            onChangeYAxis={handleChangeYAxis}
            useMetricToDrawChart={isAccessReportDiscount}
            useMetricToDrawHeatMap={isShowHeatMap}
            optionsHeatMap={optionsHeatMap}
            onChangeHeatmaps={onChangeHeatmaps}
          />
        </div >

        {/* Filtertag */}
        {!!removeInvalidFilterFromFilterTag(filterTag).length ? (
          <div className="hrv-report-row hrv-report-px-16 hrv-report-mb-8">

            {removeInvalidFilterFromFilterTag(filterTag).map((query, idx) => {
              return <ChipGroup
                query={query}
                key={`chip_hara_${idx}`}
                idx={idx}
                optionsDimension={optionDimension}
                onHandleClose={(dimension) => {
                  onHandleCloseFilterByDimension && onHandleCloseFilterByDimension(dimension)
                }}
                dateStart={dateStart}
                dateEnd={dateEnd}
                reportId={reportId}
                onHandleApplyFilterInChip={(newFilter) => {
                  const { dimension } = newFilter;
                  const filterTagDefault = [...filterTag]

                  const findIndexUpdate = filterTagDefault.findIndex(query => query.dimension === dimension);

                  filterTagDefault[findIndexUpdate] = newFilter;

                  submitFilterV2(filterTagDefault)
                }}
                disableFilterField={isVisibleDrilldown ? ["ProductCategoryId", "ProductByCategoryId"] : []}
              />
            })}
          </div>
        ) : null}

        {
          isLoadingTable && data === undefined && (
            <div className="hrv-report-mt-16">
              <SkeletonTable dataColumn={8} dataRow={data?.length || 10} />
            </div>
          )
        }

        {
          !isLoadingTable && isAccessReportDiscount && isEmpty(discount) ? (
            <div
              id="hrv-report-table-container"
              style={{
                borderLeft: 0,
                borderRight: 0,
              }}
            >
              <EmptyTemplate
                isHaveColor={true}
                text={t("Nhập tên CTKM / Mã giảm giá để xem bảng thống kê")}
              />
            </div>
          ) : (
            <>
              {isAccessReportDiscount ? (
                <>
                  {!optionCompare.length || optionCompare === t("noCompare") ? (
                    <TableByDefault
                      data={data}
                      reportNameSystem={reportNameSystem}
                      canReadMACost={canReadMACost}
                      headers={headers}
                      sortValue={sortValue}
                      summary={summary}
                      groupBySelected={groupBySelected}
                      dataLink={dataLink}
                      isHiddenPagination={isHiddenPagination}
                      isLoadingTable={isLoadingTable}
                      onChangePage={onChangePage}
                      onHandleSortTable={onHandleSortTable}
                      ref={ref}
                      dependdata={dependdata}
                    />
                  ) : (
                    <TableByDiscount
                      reportNameSystem={reportNameSystem}
                      canReadMACost={canReadMACost}
                      onHandleSortTable={onHandleSortTable}
                      summary={summary}
                    />
                  )}
                </>
              ) : (
                <TableByDefault
                  data={data}
                  reportNameSystem={reportNameSystem}
                  canReadMACost={canReadMACost}
                  headers={headers}
                  sortValue={sortValue}
                  summary={summary}
                  groupBySelected={groupBySelected}
                  dataLink={dataLink}
                  isHiddenPagination={isHiddenPagination}
                  isLoadingTable={isLoadingTable}
                  onChangePage={onChangePage}
                  onHandleSortTable={onHandleSortTable}
                  heatmapMode={isShowHeatMap}
                  benchmark={benchmark}
                  ref={ref}
                  onClickDetailDrilldown={handleClickOpenDrilldown}
                  isVisibleDrilldown={isVisibleDrilldown}
                  listProductCategoryId={listProductCategoryId?.data}
                  dependdata={dependdata}
                />
              )
              }
            </>
          )
        }
      </div >
    </div >
  );
}